/* @flow */

import React from 'react';

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import ButtonComponent from 'components/buttons/button';
import DatePickerComponent from 'components/formFields/dates/datePicker';
import FormErrorsComponent from 'components/formErrors';
import InputAmountComponent from 'components/formatters/inputAmount';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import InvoiceToSellInfo from 'models/invoices/seller/invoiceInfo';
import SellInvoiceForm from './model';

type SellInvoiceTab2ViewComponentProps = {
  errors: Map<string, ?string>;
  form: SellInvoiceForm;
  formErrors: string[];
  invoiceInfo: InvoiceToSellInfo;
  isProcessing: boolean;
  cancelInvoice: () => void;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleDateChange: (fieldName: string) => (date: moment) => void;
  submitForm: () => void;
}

export default function SellInvoiceTab2ViewComponent(props: SellInvoiceTab2ViewComponentProps) {

  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <PageSubTitleViewComponent id="page.sellInvoice.tab2.title" data-walkme="sellinvoice-title-detaildelafacture" />

      {/* {props.form.debtorCompany.isWaitingForGrade &&
        <Box mt={3} mb={3}>
          <Alert severity="info">
            <IntlMessageComponent id="page.sellInvoice.tab2.waitingGrade" />
          </Alert>
        </Box>
      } */}

      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.debtAmount"
                data-walkme="sellinvoice-field-montantdelafacture"
                label={<IntlMessageComponent id="page.sellInvoice.tab2.debtAmount.label" />}
                error={!!props.errors.get('debtAmount')}
                helperText={(props.errors.get('debtAmount')) ?
                  <IntlMessageComponent id={props.errors.get('debtAmount')} values={{minDebtAmount: props.invoiceInfo.minDebtAmount}} /> : ''}
                value={props.form.debtAmount}
                onChange={props.handleChange('debtAmount')}
                InputProps={{
                  inputComponent: InputAmountComponent,
                  startAdornment: (<InputAdornment position="start">€</InputAdornment>)
                }}
                fullWidth
                margin="normal"
                required
              />

              <TextField
                id="form.reference"
                data-walkme="sellinvoice-field-votrereference"
                label={<IntlMessageComponent id="page.sellInvoice.tab2.reference.label" />}
                error={!!props.errors.get('reference')}
                helperText={(props.errors.get('reference')) ?
                  <IntlMessageComponent id={props.errors.get('reference')} /> : ''}
                value={props.form.reference}
                onChange={props.handleChange('reference')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <DatePickerComponent
                id="form.issueDate"
                label={<IntlMessageComponent id="page.sellInvoice.tab2.issueDate.label" />}
                error={!!props.errors.get('issueDate')}
                helperText={(props.errors.get('issueDate')) ?
                  <IntlMessageComponent id={props.errors.get('issueDate')} /> : ''}
                value={props.form.issueDate}
                onChange={props.handleDateChange('issueDate')}
                fullWidth
                margin="normal"
                required
              />

              <DatePickerComponent
                id="form.dueDate"
                label={<IntlMessageComponent id="page.sellInvoice.tab2.dueDate.label" />}
                error={!!props.errors.get('dueDate')}
                helperText={(props.errors.get('dueDate')) ?
                  <IntlMessageComponent id={props.errors.get('dueDate')} values={{minDaysToSell: props.invoiceInfo.minDaysToSell, maxDaysToSell: props.invoiceInfo.maxDaysToSell}} /> : ''}
                value={props.form.dueDate}
                onChange={props.handleDateChange('dueDate')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>   
        </Grid>
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="default"
          variant="contained"
          disabled={props.isProcessing}
          onClick={props.cancelInvoice}
        ><IntlMessageComponent id="page.sellInvoice.button.cancelInvoice" /></ButtonComponent>

        <Box component="span" ml={1}>
          <ButtonComponent
            color="primary"
            variant="contained"
            isProcessing={props.isProcessing}
            onClick={props.submitForm}
          ><IntlMessageComponent id="page.sellInvoice.tab2.button.validate" /></ButtonComponent>
        </Box>

        <MandatoryFormFieldsComponent/>
      </Box>
    </React.Fragment>
  );
}