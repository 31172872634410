/* @flow */


import React from 'react';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import TagManager from 'react-gtm-module';

import * as reactGA from 'lib/analytics';

import LoadingComponent from 'components/loading';

import SignUpTab1ViewComponent from './view';

import CompanyModel from 'models/companies/company';
import RegisterCompanyForm from './model';

import RegisterService from 'services/registerService';
import { getISOAndCampaignRequest } from 'lib/marketing';
import { handleApiFormResponse, handleFormChange, handleFormValidation } from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

type SignUpTab11ComponentProps = {
  cookies: Cookies;
  languageId: number;
  successHandler: (email: string, leadId: number, companyId: number, mustAnswerEligibilityTest: boolean, buyerBlocked: boolean) => void;
  successHandlerTab1NoCompany: () => void;
}

type SignUpTab11ComponentState = {
  constraints: any;
  errors: Map<string,?string>;
  form: RegisterCompanyForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
  countryId: number;
}

class SignUpTab1Component extends React.Component<SignUpTab11ComponentProps, SignUpTab11ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: new RegisterCompanyForm(),
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    reactGA.initializeGA();
    reactGA.logEvent('SignUp', 'Start Signup');

    try {
      const constraints = await loadConstraints('Person', ['firstName', 'lastName', 'mobilePresence', 'email', 'functionName', 'company', 'companyType']);
      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  componentWillUnmount = async () => {
    reactGA.logEvent('SignUp', 'Confirmation Email Sent');
  }

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleMobileChange = (value: string) => {
    handleFormChange.call(this, 'mobilePresence', value);
  }
  validateForm = () => handleFormValidation.call(this);

  handleCompanyChange = (company: ?CompanyModel) => {
    const form = this.state.form;
    form.company = company;

    const errors = this.state.errors;
    errors.delete('company');

    this.setState({ errors, form });
  };

  handleCountryChange = (countryId: ?number) => {
    this.setState({ countryId });
  };

  handleCompanyTypeChange = (companyType: number) => {
    const form = this.state.form;
    form.companyType = companyType;
    form.company = null;

    const errors = this.state.errors;
    errors.delete('company');

    this.setState({ errors, form });
  };

  submitForm = async () => {
    if (!this.validateForm()) {
      const request = this.state.form.toIncompleteAlertRequest(this.props.languageId, "Subscription");
      const response = await RegisterService.incompleteAlert(request);
      return;
    }

    this.setState({ formErrors: [], isProcessing: true });

    try {
      const request = this.state.form.toRequest(this.props.languageId);
      request.isoAndCampaign = getISOAndCampaignRequest(this.props.cookies);

      const response = await RegisterService.registerCompany(request);
      TagManager.dataLayer({ dataLayer: { event: 'offerSellerInfoValidated', formType: 'Subscription' } });
      this.props.successHandler(this.state.form.email, response.leadId, response.companyId, response.mustAnswerEligibilityTest, response.buyerBlocked);
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  };

  render() {
    if (this.state.isLoading) return (<LoadingComponent />);

    return (
      <SignUpTab1ViewComponent
        errors={this.state.errors}
        form={this.state.form}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleChange={this.handleChange}
        handleCompanyChange={this.handleCompanyChange}
        handleCountryChange={this.handleCountryChange}
        handleCompanyTypeChange={this.handleCompanyTypeChange}
        handleMobileChange={this.handleMobileChange}
        submitForm={this.submitForm}
        isoNoCompany={this.props.successHandlerTab1NoCompany}
        countryId={this.state.countryId}
      />
    );
  }
}

const mapStateToProps = state => ({
  languageId: state.locale.languageId
});

export default connect(mapStateToProps)(withCookies(SignUpTab1Component));
