/* @flow */

import moment from 'moment';

import Company from 'models/companies/company';

export default class Invoice {
  id: string;
  invoiceName: string;

  reference: string;
  debtorReference: string;

  debtorRefusedReason: string;

  sellerCompany: Company;

  StatusKey: string;

  debtAmount: number;
  financedAmount: number;
  outstandingBalance: number;

  dueDate: moment;
  issueDate: moment;

  daysToDueDate: number;
  validationAge: ?number;

  paymentPromiseDate: ?moment;

  invoiceDocumentId: number;
  hasRelatedDocuments: bool;

  toApprove: boolean;
  approved: boolean;
  refused: boolean;
  toPay: boolean;
  paid: boolean;

  isSelected: boolean;

  approvedBy: string;

  requiresDebtRecognition: boolean;

  constructor() {
    this.isSelected = false;
  }
}
