/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import BuyerTransferInvoicesPaymentConfirmedViewComponent from './transferInvoicePaymentConfirmed';
import BuyerTransferInvoicesPaymentDelayedViewComponent from './transferInvoicePaymentDelayed';
import BuyerTransferInvoicesPaymentSummaryComponent from './transferInvoicePaymentSummary';

import BuyerInvoice from 'models/buyers/invoices/invoice';
import WireTransferPayment from 'models/payments/wireTransferPayment';

import ConfirmInvoicePaymentRequest from 'models/requests/buyers/confirmInvoicePaymentRequest';

import BuyerService from 'services/BuyerService';
import {handleApiFormResponse} from 'lib/forms';

type BuyerTransferInvoicesPaymentComponentProps = {
  activeCompanyId: number;
  activeCompanyInvestmentProfileId: number;
  invoices: BuyerInvoice[];
  wireTransferPayment: WireTransferPayment;
}

type BuyerTransferInvoicesPaymentComponentState = {
  formErrors: string[];
  isProcessing: boolean;
  step: number;
}

class BuyerTransferInvoicesPaymentComponent extends React.Component<BuyerTransferInvoicesPaymentComponentProps, BuyerTransferInvoicesPaymentComponentState> {
  
  constructor(props) {
    super(props);
    this.state = {
      formErrors: [],
      isProcessing: false,
      step: 0
    };
  }

  confirmPayment = async () => {
    this.setState({isProcessing: true});

    try {
      const promises = this.props.invoices.map(i => {
        const request = new ConfirmInvoicePaymentRequest(this.props.activeCompanyId, this.props.activeCompanyInvestmentProfileId, i.id);
        return BuyerService.confirmInvoicePayment(request);
      });

      await Promise.all(promises);

      this.setState({step: 1});
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  payLater = () => this.setState({step: 2});

  render() {
    return (
      <React.Fragment>
        {this.state.step === 0 &&
          <BuyerTransferInvoicesPaymentSummaryComponent
            invoices={this.props.invoices}
            wireTransferPayment={this.props.wireTransferPayment}
            confirmPayment={this.confirmPayment}
            payLater={this.payLater}
          />
        }

        {this.state.step === 1 &&
          <BuyerTransferInvoicesPaymentConfirmedViewComponent invoices={this.props.invoices} wireTransferPayment={this.props.wireTransferPayment}/>
        }

        {this.state.step === 2 &&
          <BuyerTransferInvoicesPaymentDelayedViewComponent invoices={this.props.invoices} wireTransferPayment={this.props.wireTransferPayment}/>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyInvestmentProfileId: state.auth.user.activeCompany.investmentProfile.id
});

export default connect(mapStateToProps)(BuyerTransferInvoicesPaymentComponent);