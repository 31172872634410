/* @flow */

import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { isCountryFranceOrDomTom } from 'lib/countryHelpers';

import PhoneNumberComponent from 'components/formFields/phoneNumber';
import ButtonComponent from 'components/buttons/button';
import ComponentWrapperComponent from 'components/websiteStructure/componentWrapper';
import CountryFieldComponent from 'components/formFields/countries';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import LegalFormFieldComponent from 'components/formFields/types/legalForm';
import SourceOfFundsFieldComponent from 'components/formFields/types/sourceOfFunds';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import SecurityComponent from 'components/security';
import TooltipComponent from 'components/toolTips/index';

import CompanyDetailsFormModel from './model';
import { SOURCEOFFUNDS_OTHER } from 'constants/constants';

type CompanyDetailsViewComponentProps = {
  errors: Map<string,?string>;
  form: CompanyDetailsFormModel;
  formErrors: string[];
  isProcessing: boolean;
  handleBooleanChange: () => void;
  handlePhoneChange: (value: string) => void;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleTypeIdChange: () => void;
  submitForm: () => void;
}

export default function CompanyDetailsViewComponent(props: CompanyDetailsViewComponentProps) {
  const isSellerObserver = useSelector(state => state.auth.user.activeCompany.roleSeller?.role == 3884);
  const isBuyerObserver = useSelector(state => state.auth.user.activeCompany.roleBuyer?.role == 3886);
  const isISONetworkManager = useSelector(state => state.auth.user.activeCompany.roleISO?.role == 3724);
  return (
    <ComponentWrapperComponent small>
      <FormErrorsComponent formErrors={props.formErrors} />

      <PageSubTitleViewComponent id="page.company.details.generalinfo.h2" />

      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.name"
                label={<IntlMessageComponent id="form.company.name.label" />}
                error={!!props.errors.get('name')}
                helperText={(props.errors.get('name')) ?
                  <IntlMessageComponent id={props.errors.get('name')} /> : ''}
                value={props.form.name}
                onChange={props.handleChange('name')}
                fullWidth
                margin="normal"
                required
                InputProps={{
                  readOnly: isSellerObserver || isBuyerObserver || isISONetworkManager,
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <LegalFormFieldComponent
                id="form.legalFormId"
                label={<IntlMessageComponent id="form.legalForm.label" />}
                error={!!props.errors.get('legalFormId')}
                helperText={props.errors.get('legalFormId') ?
                  <IntlMessageComponent id={props.errors.get('legalFormId')} /> : ''}
                countryId={props.form.countryId}
                value={props.form.legalFormId}
                changeHandler={props.handleTypeIdChange('legalFormId')}
                fullWidth
                margin="normal"
                required
                readOnly={isSellerObserver || isBuyerObserver || isISONetworkManager}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.vatNumber"
                label={<IntlMessageComponent id="form.vatNumber.label" />}
                error={!!props.errors.get('vatNumber')}
                helperText={(props.errors.get('vatNumber')) ?
                  <IntlMessageComponent id={props.errors.get('vatNumber')} /> : ''}
                value={props.form.vatNumber}
                onChange={props.handleChange('vatNumber')}
                fullWidth
                margin="normal"
                required
                InputProps={{
                  readOnly: isSellerObserver || isBuyerObserver || isISONetworkManager,
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6} pageName="Tooltip.CompanyInfo" elementName="ISIN">
            <Box px={4}>
              <TextField
                id="form.isin"
                data-walkme="companyinfo-field-isin"
                label={<IntlMessageComponent id="form.isin.label" />}
                error={!!props.errors.get('isin')}
                helperText={(props.errors.get('isin')) ?
                  <IntlMessageComponent id={props.errors.get('isin')} /> : ''}
                value={props.form.isin}
                InputProps={{
                  startAdornment: (<React.Fragment><TooltipComponent pageName="Tooltip.CompanyInfo" elementName="ISIN" /> &nbsp; </React.Fragment>),
                  readOnly: isSellerObserver || isBuyerObserver || isISONetworkManager
                }}
                onChange={props.handleChange('isin')}
                fullWidth
                margin="normal"
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.stockExchange"
                data-walkme="companyinfo-field-stockmarket"
                label={<IntlMessageComponent id="form.stockExchange.label" />}
                error={!!props.errors.get('stockExchange')}
                helperText={(props.errors.get('stockExchange')) ?
                  <IntlMessageComponent id={props.errors.get('stockExchange')} /> : ''}
                value={props.form.stockExchange}
                onChange={props.handleChange('stockExchange')}
                fullWidth
                margin="normal"
                InputProps={{
                  readOnly: isSellerObserver || isBuyerObserver || isISONetworkManager,
                }}
              />
            </Box>
          </Grid>

          {(props.form.isSeller || props.form.isBuyer || props.form.isConfirming) && (
            <>
              <Grid item xs={12} md={6}>
                <Box px={4}>
                  <TextField
                    id="form.activities"
                    data-walkme="companyinfo-field-activities"
                    label={<IntlMessageComponent id="form.activities.label" />}
                    error={!!props.errors.get('activities')}
                    helperText={(props.errors.get('activities')) ?
                      <IntlMessageComponent id={props.errors.get('activities')} /> : ''}
                    value={props.form.activities}
                    onChange={props.handleChange('activities')}
                    fullWidth
                    margin="normal"
                    required
                    InputProps={{
                      readOnly: isSellerObserver || isBuyerObserver || isISONetworkManager,
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box px={4}>
                  <CountryFieldComponent
                    id="form.mainActivityCountryId"
                    label={<IntlMessageComponent id="form.mainActivityCountry.label" />}
                    helperText={props.errors.get('mainActivityCountryId') ?
                      <IntlMessageComponent id={props.errors.get('mainActivityCountryId')} /> : ''}
                    error={!!props.errors.get('mainActivityCountryId')}
                    value={props.form.mainActivityCountryId}
                    changeHandler={props.handleTypeIdChange('mainActivityCountryId')}
                    fullWidth
                    margin="normal"
                    required
                    readOnly={isSellerObserver || isBuyerObserver || isISONetworkManager}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6} pageName="Tooltip.CompanyInfo" elementName="SourceOfFunds">
                <Box px={4}>
                  <SourceOfFundsFieldComponent
                    id="form.sourceOfFunds"
                    data-walkme="companyinfo-field-sourceOfFunds"
                    label={<IntlMessageComponent id="form.sourceOfFunds.label" />}
                    error={!!props.errors.get('sourceOfFundsId')}
                    helperText={(props.errors.get('sourceOfFundsId')) ?
                      <IntlMessageComponent id={props.errors.get('sourceOfFundsId')} /> : ''}
                    value={props.form.sourceOfFundsId}
                    InputProps={{
                      startAdornment: (<React.Fragment><TooltipComponent pageName="Tooltip.CompanyInfo" elementName="SourceOfFunds" />&nbsp; </React.Fragment>),
                      readOnly: isSellerObserver || isBuyerObserver || isISONetworkManager
                    }}
                    changeHandler={props.handleTypeIdChange('sourceOfFundsId')}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Box>
              </Grid>
              {props.form.sourceOfFundsId === SOURCEOFFUNDS_OTHER &&
                <Grid item xs={12} md={6} pageName="Tooltip.CompanyInfo" elementName="SourceOfFundsSpecification">
                  <Box px={4}>
                    <TextField
                      id="form.sourceOfFundsSpecification"
                      data-walkme="companyinfo-field-sourceOfFundsSpecification"
                      label={<IntlMessageComponent id="form.sourceOfFundsSpecification.label" />}
                      error={!!props.errors.get('sourceOfFunds')}
                      helperText={(props.errors.get('sourceOfFunds')) ?
                        <IntlMessageComponent id={props.errors.get('sourceOfFunds')} /> : ''}
                      value={props.form.sourceOfFunds}
                      onChange={props.handleChange('sourceOfFunds')}
                      fullWidth
                      margin="normal"
                      required
                    />
                  </Box>
                </Grid>
              }
            </>
          )}

          {props.form.isISO && isCountryFranceOrDomTom(props.form.countryId) && <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Box px={5} py={3}>
              <FormControl component="fieldset" fullWidth required disabled={isSellerObserver || isBuyerObserver || isISONetworkManager}>
                <FormLabel component="legend">
                  <IntlMessageComponent id="form.orias.label" data-walkme="companyinfo-orias" />
                </FormLabel>

                <RadioGroup
                  aria-label="orias"
                  name="orias"
                  onChange={props.handleBooleanChange('orias')}
                  row
                  value={props.form.orias.toString()}
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label={<IntlMessageComponent id="general.label.yes" />}
                    value="true"
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label={<IntlMessageComponent id="general.label.no" />}
                    value="false"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>}
        </Grid>
      </Box>

      <Box mt={5}>
        <PageSubTitleViewComponent id="page.company.details.address.h2" />
      </Box>

      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.addressLine1"
                label={<IntlMessageComponent id="form.company.addressLine1.label" />}
                error={!!props.errors.get('addressLine1')}
                helperText={(props.errors.get('addressLine1')) ?
                  <IntlMessageComponent id={props.errors.get('addressLine1')} /> : ''}
                value={props.form.addressLine1}
                onChange={props.handleChange('addressLine1')}
                fullWidth
                margin="normal"
                required
                InputProps={{
                  readOnly: isSellerObserver || isBuyerObserver || isISONetworkManager,
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.addressLine2"
                label={<IntlMessageComponent id="form.company.addressLine2.label" />}
                error={!!props.errors.get('addressLine2')}
                helperText={(props.errors.get('addressLine2')) ?
                  <IntlMessageComponent id={props.errors.get('addressLine2')} /> : ''}
                value={props.form.addressLine2}
                onChange={props.handleChange('addressLine2')}
                fullWidth
                margin="normal"
                InputProps={{
                  readOnly: isSellerObserver || isBuyerObserver || isISONetworkManager,
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.zipCode"
                label={<IntlMessageComponent id="form.company.zipCode.label" />}
                error={!!props.errors.get('zipCode')}
                helperText={(props.errors.get('zipCode')) ?
                  <IntlMessageComponent id={props.errors.get('zipCode')} /> : ''}
                value={props.form.zipCode}
                onChange={props.handleChange('zipCode')}
                fullWidth
                margin="normal"
                required
                InputProps={{
                  readOnly: isSellerObserver || isBuyerObserver || isISONetworkManager,
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.city"
                label={<IntlMessageComponent id="form.company.city.label" />}
                error={!!props.errors.get('city')}
                helperText={(props.errors.get('city')) ?
                  <IntlMessageComponent id={props.errors.get('city')} /> : ''}
                value={props.form.city}
                onChange={props.handleChange('city')}
                fullWidth
                margin="normal"
                required
                InputProps={{
                  readOnly: isSellerObserver || isBuyerObserver || isISONetworkManager,
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <CountryFieldComponent
                id="form.country"
                label={<IntlMessageComponent id="form.company.country.label" />}
                helperText={props.errors.get('countryId') ?
                  <IntlMessageComponent id={props.errors.get('countryId')} /> : ''}
                error={!!props.errors.get('countryId')}
                value={props.form.countryId}
                changeHandler={props.handleTypeIdChange('countryId')}
                fullWidth
                margin="normal"
                required
                readOnly={isSellerObserver || isBuyerObserver || isISONetworkManager}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <PhoneNumberComponent
                id="form.phone"
                label={<IntlMessageComponent id="form.company.phone.label" />}
                error={!!props.errors.get('phone')}
                helperText={(props.errors.get('phone')) ?
                  <IntlMessageComponent id={props.errors.get('phone')} /> :
                  <IntlMessageComponent id="form.phone.helperText" />
                }
                value={props.form.phone}
                onChange={props.handlePhoneChange}
                fullWidth
                margin="normal"
                required
                InputProps={{
                  readOnly: isSellerObserver || isBuyerObserver || isISONetworkManager,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={5}>
        <SecurityComponent pageName="POS.CompanyInformation" elementName="btnUpdateCompany">
          <ButtonComponent
            color="primary"
            variant="contained"
            isProcessing={props.isProcessing}
            onClick={props.submitForm}
          ><IntlMessageComponent id="page.modifyCompany.save" /></ButtonComponent>

          <MandatoryFormFieldsComponent />
        </SecurityComponent>
      </Box>
    </ComponentWrapperComponent>
  );
}
