import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { isCountryFranceOrDomTom } from 'lib/countryHelpers';

import PhoneNumberComponent from 'components/formFields/phoneNumber';
import CompanyItemViewComponent from 'components/company/index';
import CountryFieldComponent from 'components/formFields/countries/index';
import DatePickerComponent from 'components/formFields/dates/datePicker';
import SourceOfFundsFieldComponent from 'components/formFields/types/sourceOfFunds';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import TooltipComponent from 'components/toolTips/index';

import CompanyInfoForm from '../model';
import { SOURCEOFFUNDS_OTHER } from 'constants/constants';

type SubscriptionTab1CompanyInfoViewComponentProps = {
  company: Company;
  companyDetails: CompanyDetailsFormModel;
  errors: Map<string,?string>;
  form: CompanyInfoForm;
  minDate: moment;
  handleBooleanChange: () => void;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleDateChange: () => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
  handlePhoneChange: (value: string) => void;
}

export default function SubscriptionTab1CompanyInfoViewComponent(props: SubscriptionTab1CompanyInfoViewComponentProps) {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="page.subscription.tab1.h2" />

      <Box mt={3} px={4}>
        <CompanyItemViewComponent selected company={props.company} />
      </Box>

      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <PhoneNumberComponent
                id="form.phone"
                data-walkme="subscription-field-ndetelephone"
                label={<IntlMessageComponent id="form.company.phone.label" />}
                error={!!props.errors.get('phone')}
                helperText={(props.errors.get('phone')) ?
                  <IntlMessageComponent id={props.errors.get('phone')} /> : <IntlMessageComponent id="form.company.phone.helperText" />}
                value={props.form.phone}
                onChange={props.handlePhoneChange}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6} pageName="Tooltip.Subscription.CompanyInfo" elementName="ISIN">
            <Box px={4}>
              <TextField
                id="form.isin"
                data-walkme="subscription-field-isin"
                label={<IntlMessageComponent id="form.isin.label" />}
                error={!!props.errors.get('isin')}
                helperText={(props.errors.get('isin')) ?
                  <IntlMessageComponent id={props.errors.get('isin')} /> : ''}
                InputProps={{
                  startAdornment: (<React.Fragment><TooltipComponent pageName="Tooltip.Subscription.CompanyInfo" elementName="ISIN" />&nbsp; </React.Fragment>)
                }}
                value={props.form.isin}
                onChange={props.handleChange('isin')}
                fullWidth
                margin="normal"
              />
            </Box>
          </Grid>

          {(props.companyDetails.isSeller || props.companyDetails.isBuyer || props.companyDetails.isConfirming) && (
            <>
              <Grid item xs={12} md={6}>
                <Box px={4}>
                  <TextField
                    id="form.activities"
                    data-walkme="subscription-field-activities"
                    label={<IntlMessageComponent id="form.activities.label" />}
                    error={!!props.errors.get('activities')}
                    helperText={(props.errors.get('activities')) ?
                      <IntlMessageComponent id={props.errors.get('activities')} /> : ''}
                    value={props.form.activities}
                    onChange={props.handleChange('activities')}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box px={4}>
                  <CountryFieldComponent
                    id="form.mainActivityCountry"
                    label={<IntlMessageComponent id="form.mainActivityCountry.label" />}
                    error={!!props.errors.get('mainActivityCountryId')}
                    helperText={props.errors.get('mainActivityCountryId') ?
                      <IntlMessageComponent id={props.errors.get('mainActivityCountryId')} /> : ''}
                    value={props.form.mainActivityCountryId}
                    changeHandler={props.handleTypeChange('mainActivityCountryId')}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6} pageName="Tooltip.Subscription.CompanyInfo" elementName="SourceOfFunds">
                <Box px={4}>
                  <SourceOfFundsFieldComponent
                    id="form.sourceOfFunds"
                    data-walkme="companyinfo-field-sourceOfFunds"
                    label={<IntlMessageComponent id="form.sourceOfFunds.label" />}
                    error={!!props.errors.get('sourceOfFundsId')}
                    helperText={(props.errors.get('sourceOfFundsId')) ?
                      <IntlMessageComponent id={props.errors.get('sourceOfFundsId')} /> : ''}
                    value={props.form.sourceOfFundsId}
                    InputProps={{
                      startAdornment: (<React.Fragment><TooltipComponent pageName="Tooltip.Subscription.CompanyInfo" elementName="SourceOfFunds" />&nbsp; </React.Fragment>)
                    }}
                    changeHandler={props.handleTypeChange('sourceOfFundsId')}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Box>
              </Grid>
              {props.form.sourceOfFundsId === SOURCEOFFUNDS_OTHER &&
                <Grid item xs={12} md={6} pageName="Tooltip.Subscription.CompanyInfo" elementName="SourceOfFunds">
                  <Box px={4}>
                    <TextField
                      id="form.sourceOfFundsSpecification"
                      data-walkme="subscription-field-sourceOfFundsSpecification"
                      label={<IntlMessageComponent id="form.sourceOfFundsSpecification.label" />}
                      error={!!props.errors.get('sourceOfFunds')}
                      helperText={(props.errors.get('sourceOfFunds')) ?
                        <IntlMessageComponent id={props.errors.get('sourceOfFunds')} /> : ''}
                      value={props.form.sourceOfFunds}
                      onChange={props.handleChange('sourceOfFunds')}
                      fullWidth
                      margin="normal"
                      required
                    />
                  </Box>
                </Grid>
              }
            </>
          )}

          {props.form.isIso && isCountryFranceOrDomTom(props.form.countryId) && <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Box px={5} py={3}>
              <FormControl component="fieldset" fullWidth required>
                <FormLabel component="legend">
                  <IntlMessageComponent id="form.orias.label" data-walkme="companyinfo-orias" />
                </FormLabel>

                <RadioGroup
                  aria-label="orias"
                  name="orias"
                  onChange={props.handleBooleanChange('orias')}
                  row
                  value={props.form.orias.toString()}
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label={<IntlMessageComponent id="general.label.yes" />}
                    value="true"
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label={<IntlMessageComponent id="general.label.no" />}
                    value="false"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>}
        </Grid>
      </Box>

      {props.company.countryId === 335 &&
        <Box mt={3}>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} md={6}>
              <Box px={4}>
                <TextField
                  id="form.commercialRegistrationOnlineCode"
                  data-walkme="subscription-field-commercialRegistrationOnlineCode"
                  label={<IntlMessageComponent id="form.commercialRegistrationOnlineCode.label" />}
                  error={!!props.errors.get('commercialRegistrationOnlineCode')}
                  helperText={(props.errors.get('commercialRegistrationOnlineCode')) ?
                    <IntlMessageComponent id={props.errors.get('commercialRegistrationOnlineCode')} /> : ''}
                  value={props.form.commercialRegistrationOnlineCode}
                  onChange={props.handleChange('commercialRegistrationOnlineCode')}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box px={4}>
                <DatePickerComponent
                  id="form.commercialRegistrationOnlineCodeExpiry"
                  label={<IntlMessageComponent id="form.commercialRegistrationOnlineCodeExpiry.label" />}
                  error={!!props.errors.get('commercialRegistrationOnlineCodeExpiry')}
                  helperText={(props.errors.get('commercialRegistrationOnlineCodeExpiry')) ?
                    <IntlMessageComponent id={props.errors.get('commercialRegistrationOnlineCodeExpiry')} /> : ''}
                  value={props.form.commercialRegistrationOnlineCodeExpiry}
                  onChange={props.handleDateChange('commercialRegistrationOnlineCodeExpiry')}
                  minDate={props.minDate}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box px={4}>
                <TextField
                  id="form.companyCardOnlineCode"
                  data-walkme="subscription-field-companyCardOnlineCode"
                  label={<IntlMessageComponent id="form.companyCardOnlineCode.label" />}
                  error={!!props.errors.get('companyCardOnlineCode')}
                  helperText={(props.errors.get('companyCardOnlineCode')) ?
                    <IntlMessageComponent id={props.errors.get('companyCardOnlineCode')} /> : ''}
                  value={props.form.companyCardOnlineCode}
                  onChange={props.handleChange('companyCardOnlineCode')}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      }
    </React.Fragment>
  );
}