/* @flow */

import Company from 'models/companies/company';

export default class CompanySubscriptionInfo {
  company: Company;
  isAdministrator: ?boolean;
  lastStep: number;
  showCompanyTab: boolean;  
  showDocumentTab: boolean;
  showInvestmentProfileTab: boolean;
  showLegalTab: boolean;
  showPersonTab: boolean;
  generalConditionsSigned: boolean;
  generalConditionsNotNeeded: boolean;
  hasPaidMembership: boolean;
  skipGcStep: boolean;
}
