/* @flow */

import React from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import AmountFormatterComponent from 'components/formatters/amount';
import IntlMessageComponent from 'components/formatters/intlMessage';
import ButtonComponent from 'components/buttons/button';

import BuyerTransferInvoicesTableViewComponent from '../invoiceTable';

import BuyerInvoice from 'models/buyers/invoices/invoice';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import { PROTECTED_APP, PROTECTED_BUYER_PORTFOLIO } from 'constants/pageRoutes';
type BuyerTransferInvoicesMandateViewComponentProps = {
  invoices: BuyerInvoice[];
}

export default function BuyerTransferInvoicesMandateViewComponent(props: BuyerTransferInvoicesMandateViewComponentProps) {
  let totalPurchasePrice = 0;
  props.invoices.forEach(i => totalPurchasePrice += (i.isConfirming ? i.financedAmount : i.purchasePrice));
  const history = useHistory();
  return (
    <React.Fragment>
      <Box>
        <PageSubTitleViewComponent id="buyer.transfer.mandated.title" />
        <IntlMessageComponent id="buyer.transfer.mandated.description" />
      </Box>

      <Box mt={3}>
        <Box width="50%" display="flex">
          <Box p={1} flexGrow={1}>
            {props.invoices.some(i => i.isConfirming) && <IntlMessageComponent id="buyer.transfer.pay.confirmingtransfer" />}
            {props.invoices.some(i => !i.isConfirming) && <IntlMessageComponent id="buyer.transfer.mandated.purchacePrice" />}
          </Box>
          <Box p={1}>
            <Box component="div" fontSize={30} fontWeight="fontWeightBold" textAlign="right">
              <AmountFormatterComponent amount={totalPurchasePrice} />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mt={5}>
        <PageSubTitleViewComponent id="buyer.transfer.mandated.invoiceList.title" />
        <BuyerTransferInvoicesTableViewComponent
          invoices={props.invoices}
          showConfirming={props.invoices.filter(i => i.isConfirming === true).length === props.invoices.length} 
          />
      </Box>


      <div className="hiddenDesktop">
        <Box mt={3}>
          <ButtonComponent color="secondary" variant="contained" onClick={() => history.push(PROTECTED_APP)}>
            <IntlMessageComponent id="buyer.transfer.pay.confirm.btn.backHome" />
          </ButtonComponent>
        </Box>
      </div>
    </React.Fragment>
  );
}
