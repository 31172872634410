/* @flow */

import React from 'react';
import TagManager from 'react-gtm-module';

import * as reactGA from 'lib/analytics';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import EligibilityTestComponent from 'components/eligibilityTest';
import IneligibleLeadComponent from 'components/eligibilityTest/ineligibleLead';
import IntlMessageComponent from 'components/formatters/intlMessage';
import LoadingComponent from 'components/loading';

import SendVerificationEmailRequest from 'models/requests/register/sendVerificationEmailRequest';

import RegisterService from 'services/registerService';
import { handleApiFormResponse } from 'lib/forms';
import NotificationHelper from 'lib/notifications';

type SignUpTab2ComponentProps = {
  email: string;
  leadId: number;
  successHandler: () => void
}

type SignUpTab2ComponentState = {
  ineligibleReasonIds: number[];
}

class SignUpTab2Component extends React.Component<SignUpTab2ComponentProps, SignUpTab2ComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      ineligibleReasonIds: []
    };
  }

  componentDidMount = async () => {
    reactGA.initializeGA();
    reactGA.logEvent('SignUp', 'Eligibility Test');
  }

  successHandler = async () => {
    try {
      const request = new SendVerificationEmailRequest(this.props.email);
      await RegisterService.sendVerificationEmail(request);
      TagManager.dataLayer({ dataLayer: { event: 'offerInvoiceInfoValidated', formType: 'Subscription' } });
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.props.successHandler();
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  failHandler = (ineligibleReasonIds: number[]) => {
    reactGA.initializeGA();
    reactGA.logEvent('SignUp', 'Not Eligible');
    this.setState({ ineligibleReasonIds });
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    if (this.state.ineligibleReasonIds.length > 0) {
      return (
        <IneligibleLeadComponent
          ineligibleReasonIds={this.state.ineligibleReasonIds}
          translationPrefix="page.signup.tab2"
          successHandler={this.successHandler}
        />
      );
    }

    return (
      <React.Fragment>
        <Typography variant="body1">
          <IntlMessageComponent id="page.signup.tab2.intro" />
        </Typography>

        <Box mt={3}>
          <EligibilityTestComponent
            leadId={this.props.leadId}
            mustAnswerBusinessOpportunityQuestions
            mustAnswerEligibilityQuestions
            translationPrefix="page.signup.tab2"
            successHandler={this.successHandler}
            failHandler={this.failHandler}
          />
        </Box>
      </React.Fragment>
    );
  }
}

export default SignUpTab2Component;
