/* @flow */

import moment from 'moment';
import CompanyInfo from 'models/register/companyInfo';

import SetCompanyBankAccountRequest from 'models/requests/register/setCompanyBankAccountRequest';
import SetCompanyInfoRequest from 'models/requests/register/setCompanyInfoRequest';
import {isCountryFranceOrDomTom} from 'lib/countryHelpers';

export default class CompanyInfoForm {
  phone: string;
  isin: string;

  activities: string;
  mainActivityCountryId: number;
  sourceOfFundsId: number;
  sourceOfFunds: string;

  companyCardOnlineCode: string;
  commercialRegistrationOnlineCode: string;
  commercialRegistrationOnlineCodeExpiry: moment;

  orias: boolean;
  isIso: boolean;
  countryId: number;

  bankCountryId: number;
  bankIdentifier: string;
  bankAccount: string;

  constructor(companyInfo: CompanyInfo) {
    this.phone = companyInfo.phone || '';
    this.isin = companyInfo.isin || '';

    this.activities = companyInfo.activities || '';
    this.mainActivityCountryId = companyInfo.mainActivityCountryId || 0;
    this.sourceOfFundsId = companyInfo.sourceOfFundsId || 0;
    this.sourceOfFunds = companyInfo.sourceOfFunds || '';
    this.orias = companyInfo.orias;
    this.isIso = companyInfo.isIso;
    this.countryId = companyInfo.countryId;

    this.companyCardOnlineCode = companyInfo.companyCardOnlineCode || '';
    this.commercialRegistrationOnlineCode = companyInfo.commercialRegistrationOnlineCode || '';
    this.commercialRegistrationOnlineCodeExpiry = companyInfo.commercialRegistrationOnlineCodeExpiry || moment().startOf('d');

    this.bankCountryId = companyInfo.bankAccount.bankCountryId || 0;
    this.bankIdentifier = companyInfo.bankAccount.bankBIC || '';
    this.bankAccount = companyInfo.bankAccount.bankIBAN || '';
  }

  toCompanyInfoRequest(companyId: number) {
    const request = new SetCompanyInfoRequest();
    request.companyId = companyId;
    request.phone = this.phone;
    request.isin = this.isin;
    if (this.isIso && isCountryFranceOrDomTom(this.countryId))
    {
      request.orias = this.orias;
    }
    
    request.activities = this.activities;
    request.mainActivityCountryId = this.mainActivityCountryId;
    request.sourceOfFundsId = this.sourceOfFundsId;
    request.sourceOfFunds = this.sourceOfFunds;

    request.companyCardOnlineCode = this.companyCardOnlineCode;
    request.commercialRegistrationOnlineCode = this.commercialRegistrationOnlineCode;
    request.commercialRegistrationOnlineCodeExpiry = moment(this.commercialRegistrationOnlineCodeExpiry).format('YYYY/MM/DD');
    return request;
  }

  toCompanyBankAccountRequest(companyId: number) {
    const request = new SetCompanyBankAccountRequest();
    request.companyId = companyId;
    request.bankCountryId = this.bankCountryId;
    request.bankIdentifier = this.bankIdentifier;
    request.bankAccount = this.bankAccount;
    return request;
  }
}