/* @flow */

import React from 'react';
import { connect, useSelector } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import TagManager from 'react-gtm-module';

import * as reactGA from 'lib/analytics';

import LoadingComponent from 'components/loading';

import OfferTab1LeadViewComponent from './view';
import LeadForm from './model';

import CompanyLookupForm from 'models/companies/companyLookupForm';

import CompanyLookupService from 'services/CompanyLookupService';
import RegisterService from 'services/registerService';
import LeadService from 'services/LeadService';
import ISOAndCampaignRequest from 'models/requests/marketing/isoAndCampaignRequest';
import { getISOAndCampaignRequest } from 'lib/marketing';
import { handleApiFormResponse, handleFormChange, handleFormValidation } from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';
import { validateField } from 'lib/validation/validator';

type OfferTab1LeadComponentProps = {
  cookies: Cookies;
  languageId: number;
  countries: Country[];
  successHandler: (leadId: number) => void;
}

type OfferTab1LeadComponentState = {
  constraints: any;
  errors: Map<string,?string>;
  form: LeadForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
  companyNotFound: boolean;
  companyLookUpForm: CompanyLookupForm;
}

class OfferTab1LeadComponent extends React.Component<OfferTab1LeadComponentProps, OfferTab1LeadComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: new LeadForm(),
      formErrors: [],
      isLoading: true,
      isProcessing: false,
      companyLookUpForm: new CompanyLookupForm()
    };
  }

  componentDidMount = async () => {
    reactGA.initializeGA();
    reactGA.logEvent('Offer', 'Start Offer Flow');

    try {
      // load constraints
      const constraints = await loadConstraints('Person', ['titleId', 'lastName', 'firstName', 'mobilePresence', 'email', 'company']);

      // Retrieve and validate parameters from querystring
      const form = this.state.form;
      const errors = new Map(Object.keys(constraints).map(e => [e, undefined]));
      const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
      if (queryParams.lastname !== undefined) {
        const value = queryParams.lastname;
        const validationResult = validateField(value, constraints.lastName);
        errors.set('lastName', validationResult ? validationResult.pop() : null);
        form.lastName = value;
      }
      if (queryParams.firstname !== undefined) {
        const value = queryParams.firstname;
        const validationResult = validateField(value, constraints.firstName);
        errors.set('firstName', validationResult ? validationResult.pop() : null);
        form.firstName = value;
      }
      if (queryParams.email !== undefined) {
        const value = queryParams.email;
        const validationResult = validateField(value, constraints.email);
        errors.set('email', validationResult ? validationResult.pop() : null);
        form.email = value;
      }
      if (queryParams.mobile !== undefined) {
        const value = queryParams.mobile;
        const validationResult = validateField(value, constraints.mobilePresence);
        errors.set('mobilePresence', validationResult ? validationResult.pop() : null);
        form.mobilePresence = value;
      }
      // if (queryParams.titleid !== undefined) {
      //   const value = queryParams.titleid;
      //   const validationResult = validateField(value, constraints.titleId);
      //   errors.set('titleId', validationResult ? validationResult.pop() : null);
      //   form.titleId = value;
      // }
      if (queryParams.country !== undefined && queryParams.companyidentifier !== undefined) {
        const countries = this.props.countries;
        const countryValue = queryParams.country;
        const companyidentifierValue = queryParams.companyidentifier;
        const country = countries.find((c: Country) => c.code === countryValue);
        try {
          const companies = await CompanyLookupService.lookupByVat(country.id, `${companyidentifierValue}`);
          if (companies.length > 0) {
            form.company = companies[0];
          }
        }
        catch {
          form.company = null;
        }
      }
      if (queryParams.isocompanyid !== 0) {
        const value = queryParams.isocompanyid;
        form.isoCompanyId = value;
      }

      this.setState({ constraints, errors, form, isLoading: false });
    } catch (e) {
      console.error(e);
    }
  };

  componentWillUnmount = async () => {
    reactGA.logEvent('Offer', 'Contact Info Validated');
  }

  companyNotFound = (value: boolean) => {
    this.setState({ companyNotFound: value });
  }

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleTypeChange = (fieldName: string) => (id: number) => handleFormChange.call(this, fieldName, id);
  validateForm = () => handleFormValidation.call(this);

  handleCompanyChange = (company: ?Company) => {
    const form = this.state.form;
    form.company = company;

    const errors = this.state.errors;
    errors.delete('company');

    this.setState({ errors, form });
  };
  handleMobileChange = (value: string) => {
    handleFormChange.call(this, 'mobilePresence', value);
  }

  submitForm = async () => {
    if (!this.validateForm()) {
      const request = this.state.form.toIncompleteAlertRequest(this.props.languageId, "Offer");
      const response = await RegisterService.incompleteAlert(request);
      return;
    }
    this.setState({ formErrors: [], isProcessing: true });

    try {
      // create lead
      const request = this.state.form.toRequest(this.props.languageId);
      if (this.state.form.isoCompanyId > 0) {
        let isoAndCampaign = new ISOAndCampaignRequest();
        isoAndCampaign.isoCompanyId = this.state.form.isoCompanyId;
        request.isoAndCampaign = isoAndCampaign;
      } else {
        request.isoAndCampaign = getISOAndCampaignRequest(this.props.cookies);
      }

      const leadId = await LeadService.createLead(request);
      TagManager.dataLayer({ dataLayer: { event: 'offerSellerInfoValidated', formType: 'Offer' } });
      this.props.successHandler(leadId);
    } catch (e) {
      handleApiFormResponse.call(this, e);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <OfferTab1LeadViewComponent
        errors={this.state.errors}
        form={this.state.form}
        companyLookUpForm={this.state.companyLookUpForm}
        formErrors={this.state.formErrors}
        isProcessing={this.state.isProcessing}
        handleChange={this.handleChange}
        handleTypeChange={this.handleTypeChange}
        handleCompanyChange={this.handleCompanyChange}
        handleMobileChange={this.handleMobileChange}
        companyNotFound={this.companyNotFound}
        showCompanyNotFound={this.state.companyNotFound}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  languageId: state.locale.languageId,
  countries: state.locale.countries
});

export default withRouter(connect(mapStateToProps)(withCookies(OfferTab1LeadComponent)));
