/* @flow */

import React from 'react';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AmountFormatterComponent from 'components/formatters/amount';
import CompanyAddressComponent from 'components/company/address';
import DateFormatterComponent from 'components/formatters/date';
import DebtorGradeForBuyerComponent from 'components/debtorGrades/buyer';
import IntlMessageComponent from 'components/formatters/intlMessage';
import NumberFormatterComponent from 'components/formatters/number';
import PercentageFormatterComponent from 'components/formatters/percentage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import SecurityComponent from 'components/security';
import TooltipComponent from 'components/toolTips/index';

import InvestmentProfileCheckButtonComponent from './button';

import BuyerInvoice from 'models/buyers/invoices/invoice';

import { INVOICE_STATUS_PURCHASED } from 'constants/constants';

type BuyerInvoiceDetailsSummaryComponentProps = {
  invoice: BuyerInvoice;
  handleBuy: () => void;
  handlePay: () => void;
  handleRefuse: () => void;
  currentInvestmentProfile: number;
}

export default function BuyerInvoiceDetailsSummaryComponent(props: BuyerInvoiceDetailsSummaryComponentProps) {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="buyer.invoiceDetails.summary.title" />

      <Box mt={3}>
        <Grid container direction="row" justify="space-between" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id={props.invoice.financedDate == null ?
                      "buyer.invoiceDetails.summary.sellerCountry" : "buyer.invoiceDetails.summary.sellerCompany"} />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <CompanyAddressComponent showFlag={props.invoice.financedDate == null ? false : true} company={props.invoice.sellerCompany} hideDetails={props.invoice.financedDate == null ? true : false} />
              </Box>
            </Box>

            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id={props.invoice.financedDate == null ?
                      "buyer.invoiceDetails.summary.debtorCountry" : "buyer.invoiceDetails.summary.debtorCompany"} />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <CompanyAddressComponent showFlag={props.invoice.financedDate == null ? false : true} company={props.invoice.debtorCompany} hideDetails={props.invoice.financedDate == null ? true : false} />
              </Box>
            </Box>

            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13} data-walkme="bdetails-text-solvabilite">
                    <IntlMessageComponent id="buyer.invoiceDetails.summary.insurerRating" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <DebtorGradeForBuyerComponent value={props.invoice.debtorCompany.insurerRating} lg shortMessageWhenZero={false} />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="buyer.invoiceDetails.summary.issueDate" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <DateFormatterComponent date={props.invoice.issueDate} format="DD MMMM YYYY" />
              </Box>
            </Box>

            {(props.invoice.status >= INVOICE_STATUS_PURCHASED) &&
              (
                <Box display="flex" px={4}>
                  <Box flexGrow={1} mb={1}>
                    <Typography variant="body1">
                      <Box component="span" color="grey.main" fontSize={13}>
                        <IntlMessageComponent id="buyer.invoiceDetails.summary.purchasedDate" />
                      </Box>
                    </Typography>
                  </Box>
                  <Box>
                    <DateFormatterComponent date={props.invoice.purchasedDate} format="DD MMMM YYYY" />
                  </Box>
                </Box>
              )}

            {props.invoice.investmentDate &&
              <Box display="flex" px={4}>
                <Box flexGrow={1} mb={1}>
                  <Typography variant="body1">
                    <Box component="span" color="grey.main" fontSize={13}>
                      <IntlMessageComponent id="buyer.invoiceDetails.summary.investmentDate" />
                    </Box>
                  </Typography>
                </Box>
                <Box>
                  <DateFormatterComponent date={props.invoice.investmentDate} format="DD MMMM YYYY" />
                </Box>
              </Box>
            }

            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="buyer.invoiceDetails.summary.dueDate" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <DateFormatterComponent date={props.invoice.dueDate} format="DD MMMM YYYY" />
              </Box>
            </Box>

            {props.invoice.paymentDate &&
              <Box display="flex" px={4}>
                <Box flexGrow={1} mb={1}>
                  <Typography variant="body1">
                    <Box component="span" color="grey.main" fontSize={13}>
                      <IntlMessageComponent id="buyer.invoiceDetails.summary.paymentDate" />
                    </Box>
                  </Typography>
                </Box>
                <Box>
                  <DateFormatterComponent date={props.invoice.paymentDate} format="DD MMMM YYYY" />
                </Box>
              </Box>
            }

            {props.invoice.closedDate &&
              <Box display="flex" px={4}>
                <Box flexGrow={1} mb={1}>
                  <Typography variant="body1">
                    <Box component="span" color="grey.main" fontSize={13}>
                      <IntlMessageComponent id="buyer.invoiceDetails.summary.closedDate" />
                    </Box>
                  </Typography>
                </Box>
                <Box>
                  <DateFormatterComponent date={props.invoice.closedDate} format="DD MMMM YYYY" />
                </Box>
              </Box>
            }

            {props.invoice.daysLate > 0 &&
              <Box display="flex" px={4}>
                <Box flexGrow={1} mb={1}>
                  <Typography variant="body1">
                    <Box component="span" color="grey.main" fontSize={13}>
                      <IntlMessageComponent id="buyer.invoiceDetails.summary.daysLate" />
                    </Box>
                  </Typography>
                </Box>
                <Box>
                  <NumberFormatterComponent value={props.invoice.daysLate} />
                </Box>
              </Box>
            }
          </Grid>
        </Grid>
      </Box>

      <Box mt={3} py={3} bgcolor="lightblue.main">
        <Grid container direction="row" justify="space-between" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1} data-walkme="bdetails-list-montantprefinance">
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    {props.invoice.isConfirming && <IntlMessageComponent id='buyer.invoices.amountToTransfer' />}
                    {!props.invoice.isConfirming && <IntlMessageComponent id="buyer.invoiceDetails.summary.financedAmount" />}
                  </Box>
                </Typography>
              </Box>
              <Box>
                <AmountFormatterComponent amount={props.invoice.financedAmount} />
              </Box>
            </Box>

            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="buyer.invoiceDetails.summary.buyerInsuranceFee" />
                  </Box>
                </Typography>
              </Box>
              <Box pageName="Tooltip.Buyer.InvoiceDetails" elementName="BuyerInsuranceFee">
                <TooltipComponent pageName="Tooltip.Buyer.InvoiceDetails" elementName="BuyerInsuranceFee">
                  <AmountFormatterComponent amount={props.invoice.buyerInsuranceFee} />
                </TooltipComponent>
              </Box>
            </Box>

            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1} data-walkme="bdetails-list-volumeassure">
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="buyer.invoiceDetails.summary.insuredPct" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <PercentageFormatterComponent value={props.invoice.insuredPct} noFractionDigits />
              </Box>
            </Box>

            {props.invoice.amountReceived !== undefined &&
              <Box display="flex" px={4}>
                <Box flexGrow={1} mb={1} data-walkme="bdetails-list-receivedamount">
                  <Typography variant="body1">
                    <Box component="span" color="grey.main" fontSize={13}>
                      <IntlMessageComponent id="buyer.invoiceDetails.summary.receivedAmount" />
                    </Box>
                  </Typography>
                </Box>
                <Box>
                  <AmountFormatterComponent amount={props.invoice.amountReceived} />
                </Box>
              </Box>
            }

            {props.invoice.amountDue !== undefined &&
              <Box display="flex" px={4}>
                <Box flexGrow={1} mb={1} data-walkme="bdetails-list-dueamount">
                  <Typography variant="body1">
                    <Box component="span" color="grey.main" fontSize={13}>
                      <IntlMessageComponent id="buyer.invoiceDetails.summary.dueAmount" />
                    </Box>
                  </Typography>
                </Box>
                <Box>
                  <AmountFormatterComponent amount={props.invoice.amountDue} />
                </Box>
              </Box>
            }

            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1} data-walkme="bdetails-list-reduction">
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="buyer.invoiceDetails.summary.discountPct" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <PercentageFormatterComponent value={props.invoice.discountPct} />
              </Box>
            </Box>

            <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1} data-walkme="bdetails-list-retourannuel">
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="buyer.invoiceDetails.summary.irr" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <PercentageFormatterComponent value={props.invoice.irr} />
              </Box>
            </Box>

            {props.invoice.closedDate && props.invoice.investmentDate && <Box display="flex" px={4}>
              <Box flexGrow={1} mb={1} data-walkme="bdetails-list-investmentdays">
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="buyer.invoiceDetails.summary.investmentdays" />
                  </Box>
                </Typography>
              </Box>
              <Box>
                <NumberFormatterComponent value={moment(props.invoice.closedDate).diff(moment(props.invoice.investmentDate), 'days') + 1} />
              </Box>
            </Box>}
          </Grid>
          {props.invoice.isConfirming !== true &&
            <Grid item xs={12} md={6}>
              <Box alignItems="center" px={4}>
                <Typography variant="body1">
                  <Box component="span" color="grey.main" fontSize={13}>
                    <IntlMessageComponent id="buyer.invoiceDetails.summary.purchasePrice" />
                  </Box>
                </Typography>
                <Typography variant="body1">
                  <Box component="span" fontSize={30} fontWeight="fontWeightBold">
                    <AmountFormatterComponent amount={props.invoice.purchasePrice} />
                  </Box>
                </Typography>
              </Box>
            </Grid>
          }
        </Grid>
      </Box>

      {(props.invoice.toPurchase || props.invoice.toPay) &&
        <Box mt={3}>
          {props.invoice.toPurchase &&
            <SecurityComponent pageName="POS.Buyers.InvoiceDetails" elementName="btnBuy" checkActiveProfile checkRoleExecute>
              <InvestmentProfileCheckButtonComponent
                id="buyer.invoiceDetails.summary.btn.buy"
                color="primary"
                action={props.handleBuy}
                targetInvestmentProfileId={props.invoice.assignedToInvestmentProfileId}
              />
            </SecurityComponent>
          }
          {props.invoice.toPay &&
            <SecurityComponent pageName="POS.Buyers.InvoiceDetails" elementName="btnPay" checkActiveProfile checkRoleExecute>
              <InvestmentProfileCheckButtonComponent
                id="buyer.invoiceDetails.summary.btn.pay"
                color="tertiary"
                action={props.handlePay}
                targetInvestmentProfileId={props.invoice.investmentProfile.id}
              />
            </SecurityComponent>
          }
          {props.invoice.assignedToBuyerAndNotRefused &&
            <Box component="span" ml={1}>
              <InvestmentProfileCheckButtonComponent
                id="buyer.invoiceDetails.summary.btn.refuse"
                color="tertiary"
                action={props.handleRefuse}
              />
            </Box>
          }
        </Box>
      }
    </React.Fragment>
  );
}
