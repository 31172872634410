/* @flow */

import React from 'react';
import { useHistory } from 'react-router-dom';

import moment from 'moment';

import Box from '@material-ui/core/Box';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import AmountFormatterComponent from 'components/formatters/amount';
import ButtonComponent from 'components/buttons/button';
import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import TimeFormatterComponent from 'components/formatters/time';

import WireTransferPayment from 'models/payments/wireTransferPayment';

import { PROTECTED_APP, PROTECTED_BUYER_PORTFOLIO } from 'constants/pageRoutes';


type BuyerTransferInvoicePaymentConfirmedViewComponentProps = {
  invoices: BuyerInvoice[];
  wireTransferPayment: WireTransferPayment;
}

export default function BuyerTransferInvoicePaymentConfirmedViewComponent(props: BuyerTransferInvoicePaymentConfirmedViewComponentProps) {
  const history = useHistory();

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="buyer.transfer.pay.confirm.title" />
      <IntlMessageComponent id="buyer.transfer.pay.confirm.description" />

      <Box mt={3}>
        <Box width="70vw" display="flex">
          <Box p={1} flexGrow={1}>
            {props.invoices.some(i => i.isConfirming) && <IntlMessageComponent id="buyer.transfer.pay.confirmingtransfer" />}
            {props.invoices.some(i => !i.isConfirming) && <IntlMessageComponent id="buyer.transfer.pay.confirm.amount" />}
          </Box>
          <Box p={1}>
            <Box component="div" textAlign="right">
              <AmountFormatterComponent amount={props.wireTransferPayment.amount} />
            </Box>
          </Box>
        </Box>

        <Box width="70vw" display="flex">
          <Box p={1} flexGrow={1}>
            <IntlMessageComponent id="buyer.transfer.pay.confirm.date" />
          </Box>
          <Box p={1}>
            <CheckCircleIcon fontSize="large" />
          </Box>
          <Box p={1}>
            <Box component="div" textAlign="right">
              <DateFormatterComponent date={moment()} />
            </Box>
            <Box component="div" textAlign="right">
              <TimeFormatterComponent date={moment()} />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mt={3}>
        <div className="hiddenMobile">
          <ButtonComponent color="secondary" variant="contained" onClick={() => history.push(PROTECTED_BUYER_PORTFOLIO)}>
            <IntlMessageComponent id="buyer.transfer.pay.confirm.btn.seePortfolio" />
          </ButtonComponent>
        </div>
        <div className="hiddenDesktop">
          <ButtonComponent color="secondary" variant="contained" onClick={() => history.push(PROTECTED_APP)}>
            <IntlMessageComponent id="buyer.transfer.pay.confirm.btn.backHome" />
          </ButtonComponent>
        </div>
      </Box>
    </React.Fragment>
  );
}
