/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import moment from 'moment';

import LoadingComponent from 'components/loading';
import ProfileFormViewComponent from './view';

import Person from 'models/users/person';
import PersonalInfoForm from './model';
import ProfileInfoSentModalComponent from './modal';

import UserService from 'services/UserService';
import { handleApiFormResponse, handleFormChange, handleFormValidation, getValidationErrors } from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';
import NotificationHelper from 'lib/notifications';

import DocumentService from 'services/DocumentService';
import { ENTITY_USER } from 'constants/constants';
import { DOCUMENTS_PERSON_POST } from 'constants/apiRoutes';

import { getDocumentTypeBack, getDocumentTypeFront, checkId } from 'lib/documentHelpers';

type ProfileFormComponentProps = {
  person: Person;
  checkFormValidAtLoad: boolean;
}

type ProfileFormComponentState = {
  constraints: any;
  errors: Map<string,?string>;
  form: PersonalInfoForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
  docToBeValidatedCount: number;
  formValidChecked: boolean;
  hasValidId: boolean;
  showModal: boolean;
}

class ProfileFormComponent extends React.Component<ProfileFormComponentProps, ProfileFormComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: null,
      formErrors: [],
      isLoading: true,
      isProcessing: false,
      docToBeValidatedCount: props.person.personDocumentsToClassifyCount || 0,
      docNotExpiredAndNotReplaced: [],
      formValidChecked: false
    };
  }

  componentDidMount = async () => {
    try {
      let constraints = await loadConstraints('Person', ['titleId', 'lastName', 'firstName', 'email',
        'phone', 'mobile', 'addressLine1', 'addressLine2', 'zipCode', 'city', 'countryId', 'birthCountryId', 'birthDate', 'birthPlace',
        'nationalityId', 'registrationTypeId', 'registrationNumber', 'registrationExpireDate', 'languageId', 'hasValidId', 'idFront', 'idBack']);

      const result = await DocumentService.getPersonNotExpiredAndNotReplacedDocuments(this.props.activeCompany.id, this.props.activeCompany.companyPersonId);
      const docNotExpiredAndNotReplaced = result.filter(row => row.entityTypeID === ENTITY_USER);
      let hasValidId = checkId(docNotExpiredAndNotReplaced, this.props.person.registrationTypeId);

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        form: new PersonalInfoForm(this.props.person, hasValidId),
        isLoading: false,
        docNotExpiredAndNotReplaced,
        hasValidId
      });

      if (!this.state.formValidChecked && (!this.state.hasValidId || this.props.checkFormValidAtLoad)) {
        this.checkFormValid();
      }
    } catch (error) {
      console.error(error);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleDateChange = (fieldName: string) => (date: moment) => handleFormChange.call(this, fieldName, date);
  handleFileChange = (fieldName: string) => (file: File) => handleFormChange.call(this, fieldName, file);
  handleTypeChange = (fieldName: string) => (id: number) => {
    handleFormChange.call(this, fieldName, id);
    if (fieldName === 'registrationTypeId' && this.props.person?.registrationTypeId > 0) {
      let form = this.state.form;
      form.registrationTypeId = id;

      let hasValidId = checkId(this.state.docNotExpiredAndNotReplaced, id);
      form.hasValidId = hasValidId;
      if (id === this.props.person.registrationTypeId) {
        handleFormChange.call(this, 'registrationNumber', this.props.person.registrationNumber);
        handleFormChange.call(this, 'registrationExpireDate', this.props.person.registrationExpireDate);
      }
      else {
        //handleFormChange.call(this, 'registrationNumber', '');
        //handleFormChange.call(this, 'registrationExpireDate', moment.utc().startOf('d'));
        form.registrationNumber = '';
        form.registrationExpireDate = moment.utc().startOf('d');
      }
      this.setState({ hasValidId, form });
    }
  }
  handleMobileChange = (value: string) => {
    handleFormChange.call(this, 'mobile', value);
  }
  handlePhoneChange = (value: string) => {
    handleFormChange.call(this, 'phone', value);
  }
  validateForm = () => handleFormValidation.call(this);

  checkFormValid = () => {
    let errors = getValidationErrors.call(this);
    this.setState({ errors: errors, formValidChecked: true });
  }

  submitForm = async () => {
    if (!this.validateForm()) return;
    this.setState({ formErrors: [], isProcessing: true });

    try {
      const request = this.state.form.toRequest();
      await UserService.updatePersonalInfo(request);
      if (this.state.form.idFront !== null) {
        let documentTypeFront = getDocumentTypeFront(this.state.form.registrationTypeId);
        await DocumentService.sendDocument2(DOCUMENTS_PERSON_POST, this.state.form.idFront, { documentTypeId: documentTypeFront });
      }
      if (this.state.form.idBack !== null) {
        let documentTypeBack = getDocumentTypeBack(this.state.form.registrationTypeId);
        await DocumentService.sendDocument2(DOCUMENTS_PERSON_POST, this.state.form.idBack, { documentTypeId: documentTypeBack });
      }
      //NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'notifications.profile.success');
      this.setState({ isProcessing: false, hasValidId: true, showModal: true });
    } catch (error) {
      handleApiFormResponse.call(this, error);
    }
  };

  hideModal = () => this.setState({ showModal: false });

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <ProfileFormViewComponent
          docToBeValidatedCount={this.state.docToBeValidatedCount}
          docNotExpiredAndNotReplaced={this.state.docNotExpiredAndNotReplaced}
          errors={this.state.errors}
          form={this.state.form}
          formErrors={this.state.formErrors}
          isProcessing={this.state.isProcessing}
          handleChange={this.handleChange}
          handleDateChange={this.handleDateChange}
          handleTypeChange={this.handleTypeChange}
          handleFileChange={this.handleFileChange}
          handleMobileChange={this.handleMobileChange}
          handlePhoneChange={this.handlePhoneChange}
          submitForm={this.submitForm}
          hasValidId={this.state.hasValidId}
        />
        {this.state.showModal &&
          <ProfileInfoSentModalComponent handleClose={this.hideModal} />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompany: state.auth.user.activeCompany,
});

export default connect(mapStateToProps)(ProfileFormComponent);
// export default ProfileFormComponent;