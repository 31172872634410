/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import customSort from 'lib/sortHelpers';

import Box from '@material-ui/core/Box';

import LoadingComponent from 'components/loading';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import BuyerInvoiceListViewComponent from './view';

import Invoice from 'models/buyers/invoices/invoice';

import BuyerService from 'services/BuyerService';

type BuyerInvoiceListComponentProps = {
  activeCompanyId: number;
  activeCompanyInvestmentProfileId: number;
  showInvestmentProfile: boolean;
}

type BuyerInvoiceListComponentState = {
  invoices: Invoice[];
  isLoading: boolean;
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
}

class BuyerInvoiceListComponent extends React.Component<BuyerInvoiceListComponentProps, BuyerInvoiceListComponentState > {

  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      isLoading: true,
      order: 'asc',
      orderBy: 'price',
      page: 0,
      pageSize: 10
    };
  }

  componentDidMount = async () => {
    try {
      const invoices = await BuyerService.getInvoices(this.props.activeCompanyId, this.props.activeCompanyInvestmentProfileId);
      this.setState({invoices, isLoading: false});
    } catch (e) {
      console.error(e);
    }
  }

  handlePageChange = (page: number) => this.setState({page});
  handlePageSizeChange = (pageSize: number) => this.setState({pageSize});

  handleSortOrderChange = (orderBy: string) => {
    let order = this.state.orderBy === orderBy && this.state.order === 'desc' ? 'asc' : 'desc';

    let invoices = null;
    if (orderBy.startsWith('debtorCompany.')) {
      // get exact property name
      let o = orderBy.substring(14);

      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b.debtorCompany[o], a.debtorCompany[o]))
        : this.state.invoices.sort((a, b) => customSort(a.debtorCompany[o], b.debtorCompany[o]));
    } 
    else if (orderBy.startsWith('investmentProfile.')) {
      // get exact property name
      let o = orderBy.substring(18);

      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b.investmentProfile[o], a.investmentProfile[o]))
        : this.state.invoices.sort((a, b) => customSort(a.investmentProfile[o], b.investmentProfile[o]));
    }
    else if (orderBy.startsWith('sellerCompany.')) {
      // get exact property name
      let o = orderBy.substring(14);

      // order
      invoices = order === 'desc'
      ? this.state.invoices.sort((a, b) => customSort(b.sellerCompany[o], a.sellerCompany[o]))
      : this.state.invoices.sort((a, b) => customSort(a.sellerCompany[o], b.sellerCompany[o]));
    }
    else {
      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b[orderBy], a[orderBy]))
        : this.state.invoices.sort((a, b) => customSort(a[orderBy], b[orderBy]));
    }

    this.setState({ invoices, order, orderBy });
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>
        <PageSubTitleViewComponent id="buyer.invoices.title" />

        <Box mt={3}>
          <BuyerInvoiceListViewComponent
            invoices={this.state.invoices}
            order={this.state.order}
            orderBy={this.state.orderBy}
            page={this.state.page}
            pageSize={this.state.pageSize}
            showInvestmentProfile={this.props.showInvestmentProfile}
            showConfirming={this.state.invoices.length > 0 && this.state.invoices.filter(i => i.isConfirming === true).length === this.state.invoices.length}
            handlePageChange={this.handlePageChange}
            handlePageSizeChange={this.handlePageSizeChange}
            handleSortOrderChange={this.handleSortOrderChange}
          />
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyInvestmentProfileId: state.auth.user.activeCompany.investmentProfile.id,
  showInvestmentProfile: state.auth.user.activeCompany.investmentProfile.id === 0 && state.auth.user.activeCompany.roleBuyer.investmentProfiles.length > 1
});

export default connect(mapStateToProps)(BuyerInvoiceListComponent);
