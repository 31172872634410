/* @flow */


import React from 'react';
import { useHistory } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';

import AmountFormatterComponent from 'components/formatters/amount';
import BuyerInvoiceStatusComponent from 'components/invoiceStatus/buyer';
import CountryNameComponent from 'components/country/name';
import DateFormatterComponent from 'components/formatters/date';
import DebtorGradeForBuyerComponent from 'components/debtorGrades/buyer';
import IntlMessageComponent from 'components/formatters/intlMessage';
import InvoiceMarketPlaceComponent from 'components/invoice/marketPlace';
import InvoiceNameComponent from 'components/invoice/name';
import PercentageFormatterComponent from 'components/formatters/percentage';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';
import InvoiceBatchIconTooltipComponent from 'components/invoice/batchIcon';
import TooltipComponent from 'components/toolTips/index';

import Invoice from 'models/buyers/invoices/invoice';

import { PROTECTED_BUYER_INVOICE_DETAILS } from 'constants/pageRoutes';

const columnData = [
  { id: 'type', align: 'left', isConfirming: false, isInvestmentProfile: false, translationKey: 'buyer.invoices.status', walkme: 'bdashboard-column2-status' },
  { id: 'id', align: 'left', isConfirming: false, isInvestmentProfile: false, translationKey: 'buyer.invoices.invoiceId', walkme: 'bdashboard-column1-nfacture' },
  { id: 'investmentProfile.name', align: 'left', isConfirming: false, isInvestmentProfile: true, translationKey: 'buyer.invoices.investmentProfile', walkme: 'bdashboard-column1-investmentProfile' },
  { id: 'financedAmount', hideConfirming: true, isConfirming: false, isInvestmentProfile: false, translationKey: 'buyer.invoices.financedAmount', walkme: 'bdashboard-column1-montantfinance' },
  { id: 'amountToTransfer', isConfirming: true, isInvestmentProfile: false, translationKey: 'buyer.invoices.amountToTransfer' },
  { id: 'purchasePrice', hideConfirming: true, isConfirming: false, isInvestmentProfile: false, translationKey: 'buyer.invoices.purchasePrice', walkme: 'bdashboard-column1-prixdachat' },
  { id: 'buyerInsuranceFee', isConfirming: false, isInvestmentProfile: false, translationKey: 'buyer.invoices.buyerInsuranceFee' },
  { id: 'issueDate', align: 'left', isConfirming: false, isInvestmentProfile: false, translationKey: 'buyer.invoices.issueDate', walkme: 'bdashboard-column1-emission' },
  { id: 'dueDate', align: 'left', isConfirming: false, isInvestmentProfile: false, translationKey: 'buyer.invoices.dueDate', walkme: 'bdashboard-column1-echeance' },
  { id: 'discountPct', isConfirming: false, isInvestmentProfile: false, translationKey: 'buyer.invoices.discountPct', walkme: 'bdashboard-column1-reduction' },
  { id: 'irr', isConfirming: false, isInvestmentProfile: false, translationKey: 'buyer.invoices.irr', walkme: 'bdashboard-column1-retourannualise' },
  { id: 'debtorCompany.insurerRating', align: 'left', isConfirming: false, isInvestmentProfile: false, translationKey: 'buyer.invoices.insurerRating', walkme: 'bdashboard-column1-gradefinancier' },
  { id: 'debtorCompany.countryId', align: 'left', isConfirming: false, isInvestmentProfile: false, translationKey: 'buyer.invoices.debtorCountry', walkme: 'bdashboard-column1-paysclient' },
  { id: 'sellerCompany.countryId', align: 'left', isConfirming: false, isInvestmentProfile: false, translationKey: 'buyer.invoices.sellerCountry', walkme: 'bdashboard-column1-paysvendeur' }
];

type BuyerInvoiceListViewComponentProps = {
  invoices: Invoice[];
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  showInvestmentProfile: boolean;
  showConfirming: boolean;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSortOrderChange: (property: string) => void;
};

export default function BuyerInvoiceListViewComponent(props: BuyerInvoiceListViewComponentProps) {
  const history = useHistory();

  const handlePageChange = (_, page) => props.handlePageChange(page);
  const handlePageSizeChange = event => props.handlePageSizeChange(event.target.value);

  const navigateToInvoiceDetails = id => history.push({ pathname: `${PROTECTED_BUYER_INVOICE_DETAILS}/${id}` });

  return (
    <React.Fragment>
      <Box component="div" overflow="auto">
        <Table>
          <StyledTableHeadComponent>
            <StyledTableRowComponent>
              {columnData.filter(column => (props.showInvestmentProfile || !column.isInvestmentProfile) && (props.showConfirming || !column.isConfirming) && (!props.showConfirming || column.hideConfirming !== true)).map(column => (
                <StyledTableCellTextComponent align={column?.align ?? 'right'} key={column.id} padding="none">
                  <TableSortLabel
                    active={props.orderBy === column.id}
                    data-walkme={column.walkme || ''}
                    direction={props.order}
                    onClick={() => props.handleSortOrderChange(column.id)}
                  ><IntlMessageComponent id={column.translationKey} /></TableSortLabel>
                </StyledTableCellTextComponent>
              ))}
            </StyledTableRowComponent>
          </StyledTableHeadComponent>

          <TableBody bgcolor="white">
            {props.invoices.length === 0 &&
              <StyledTableRowComponent>
                <StyledTableCellTextComponent colSpan={columnData.length}>
                  <Box textAlign="center">
                    <IntlMessageComponent id="general.noData" />
                  </Box>
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            }

            {props.invoices.slice(props.page * props.pageSize, (props.page * props.pageSize) + props.pageSize).map(i => (
              <StyledTableRowComponent
                key={`invList-${i.id}`}
                hover
                onClick={() => navigateToInvoiceDetails(i.id)}
              >
                <StyledTableCellTextComponent padding="none">
                  <BuyerInvoiceStatusComponent invoiceType={i.type} />
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  <Box component="span" display="flex" alignItems="center">
                    <Box component="span" flexGrow={1} px={0}>
                      <InvoiceNameComponent invoiceId={i.id} invoiceName={i.invoiceName} />
                    </Box>
                    {i.isBatch && <InvoiceBatchIconTooltipComponent />}
                    <InvoiceMarketPlaceComponent marketPlaceId={i.marketPlaceId} />
                  </Box>
                </StyledTableCellTextComponent>

                {props.showInvestmentProfile &&
                  <StyledTableCellTextComponent padding="none">
                    <Avatar style={{ fontSize: '0.8rem', height: '24px', width: '24px' }}>{i.investmentProfile.name.substring(0, 2).toUpperCase()}</Avatar>
                  </StyledTableCellTextComponent>
                }

                <StyledTableCellNumbersComponent padding="none">
                  {i.isConfirming && <b><AmountFormatterComponent amount={i.financedAmount} /></b>}
                  {!i.isConfirming && <AmountFormatterComponent amount={i.financedAmount} />}
                </StyledTableCellNumbersComponent>

                {!props.showConfirming &&
                  <StyledTableCellNumbersComponent padding="none">
                    {i.isConfirming && <b></b>}
                    {!i.isConfirming && <b><AmountFormatterComponent amount={i.purchasePrice} /></b>}
                  </StyledTableCellNumbersComponent>
                }

                <StyledTableCellNumbersComponent padding="none" pageName="Tooltip.Buyer.InvoiceColumn" elementName="BuyerInsuranceFee">
                  <TooltipComponent pageName="Tooltip.Buyer.InvoiceColumn" elementName="BuyerInsuranceFee">
                    <b><AmountFormatterComponent amount={i.buyerInsuranceFee} /></b>
                  </TooltipComponent>
                </StyledTableCellNumbersComponent>

                <StyledTableCellTextComponent padding="none">
                  <DateFormatterComponent date={i.issueDate} />
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  <DateFormatterComponent date={i.dueDate} />
                </StyledTableCellTextComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <PercentageFormatterComponent value={i.discountPct} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <b><PercentageFormatterComponent value={i.irr} /></b>
                </StyledTableCellNumbersComponent>

                <StyledTableCellTextComponent padding="none">
                  <DebtorGradeForBuyerComponent value={i.debtorCompany.insurerRating} shortMessageWhenZero />
                  <b><PercentageFormatterComponent value={i.insuredPct} noFractionDigits /></b>
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  <b><CountryNameComponent countryId={i.debtorCompany.countryId} /></b>
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  <b><CountryNameComponent countryId={i.sellerCompany.countryId} /></b>
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            ))}
          </TableBody>
        </Table>

        <Box display="flex" height={60}>
          <Box flexGrow={1}></Box>
          <Box justifyContent="flex-end">
            <TablePagination
              component="div"
              count={props.invoices.length}
              rowsPerPage={props.pageSize}
              page={props.page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePageSizeChange}
              labelRowsPerPage={<IntlMessageComponent id="general.invoicePerPage" />}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
            />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
