/* @flow */

import React from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AmountFormatterComponent from 'components/formatters/amount';
import ButtonComponent from 'components/buttons/button';
import DateFormatterComponent from 'components/formatters/date';
import IconCancelOutlined from 'components/icons/outlined/cancel';
import IntlMessageComponent from 'components/formatters/intlMessage';
import InvoiceNameComponent from 'components/invoice/name';
import NumberFormatterComponent from 'components/formatters/number';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';

import Invoice from 'models/debtors/invoice';

import { PROTECTED_DEBTOR_INVOICE_DETAILS } from 'constants/pageRoutes';

const useStyles = makeStyles(_ => ({
  lightred: {
    backgroundColor: '#ffcccc'
  }
}));

const columnData = [
  { id: 'sellerCompany.name', align: 'left', translationKey: 'debtor.approve.table.sellerCompany.name', walkme: 'debtor-approve-table-sellerCompanyName' },
  { id: 'reference', align: 'left', translationKey: 'debtor.approve.table.reference', walkme: 'debtor-approve-table-reference' },
  { id: 'debtorReference', align: 'left', translationKey: 'debtor.approve.table.debtorReference', walkme: 'debtor-approve-table-debtorReference' },
  { id: 'debtAmount', translationKey: 'confirming.approve.table.invoiceAmount', walkme: 'debtor-approve-table-debtAmount' },
  { id: 'issueDate', align: 'left', translationKey: 'debtor.approve.table.issueDate', walkme: 'debtor-approve-table-issueDate' },
  { id: 'dueDate', align: 'left', translationKey: 'debtor.approve.table.dueDate', walkme: 'debtor-approve-table-dueDate' },
  { id: 'confirmingDueDate', align: 'left', translationKey: 'confirming.approve.table.confirmingDueDate', walkme: 'debtor-approve-table-dueDate' },
  { id: 'daysToDueDate', translationKey: 'debtor.approve.table.daysToDueDate', walkme: 'debtor-approve-table-daysToDueDate' },
  { id: 'id', align: 'left', translationKey: 'debtor.approve.table.id', walkme: 'debtor-approve-table-invoiceId' }
];

type ConfirmingInvoicesToSignTableViewComponentProps = {
  invoices: Invoice[];
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  totalDebtAmount: number;
  totalSellers: number;
  canSignDebtRecognition: boolean;
  approveInvoices: () => void;
  handleInvoiceSelect: (invoiceId: number) => void;
  handleInvoiceSelectAll: () => void;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSortOrderChange: (property: string) => void;
  refuseInvoice: (invoiceId: number) => void;
};


export default function ConfirmingInvoicesToSignTableViewComponent(props: ConfirmingInvoicesToSignTableViewComponentProps) {
  const history = useHistory();

  const classes = useStyles();

  const handlePageChange = (_, page) => props.handlePageChange(page);
  const handlePageSizeChange = event => props.handlePageSizeChange(event.target.value);
  const navigateToInvoiceDetails = id => history.push({ pathname: `${PROTECTED_DEBTOR_INVOICE_DETAILS}/${id}` });

  const handleClickCheckbox = (event, invoiceId: number) => {
    event.stopPropagation();
    props.handleInvoiceSelect(invoiceId);
  };

  const handleClickCheckboxAll = (event) => {
    event.stopPropagation();
    props.handleInvoiceSelectAll();
  };

  const handleClickRefuse = (event, invoiceId: number) => {
    event.stopPropagation();
    props.refuseInvoice(invoiceId);
  };

  return (
    <React.Fragment>
      <Box component="div" overflow="auto">
        <Table>
          <StyledTableHeadComponent>
            <StyledTableRowComponent>
              {props.canSignDebtRecognition &&
                <StyledTableCellTextComponent padding="checkbox">
                  <Tooltip title={<IntlMessageComponent id="general.tooltip.selectAll" />}>
                    <Checkbox
                      checked={props.invoices.length > 0 && props.invoices.find(i => !i.isSelected) === undefined}
                      color="primary"
                      disabled={props.invoices.length === 0 || props.invoices.map(i => i.sellerCompany.id).filter((value, index, self) => self.indexOf(value) === index).length > 1}
                      onClick={event => handleClickCheckboxAll(event)}
                    />
                  </Tooltip>
                </StyledTableCellTextComponent>
              }


              {columnData.map(column => (
                <StyledTableCellTextComponent align={column?.align ?? 'right'} key={column.id} padding="none">
                  <TableSortLabel
                    active={props.orderBy === column.id}
                    direction={props.order}
                    onClick={() => props.handleSortOrderChange(column.id)}
                    data-walkme={column.walkme || ''}
                  ><IntlMessageComponent id={column.translationKey} /></TableSortLabel>
                </StyledTableCellTextComponent>
              ))}

              <StyledTableCellTextComponent padding="none" />
            </StyledTableRowComponent>
          </StyledTableHeadComponent>

          <TableBody bgcolor="white">
            {props.invoices.length === 0 &&
              <StyledTableRowComponent>
                <StyledTableCellTextComponent colSpan={columnData.length + 2}>
                  <Box textAlign="center">
                    <IntlMessageComponent id="general.noData" />
                  </Box>
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            }

            {props.invoices.slice(props.page * props.pageSize, (props.page * props.pageSize) + props.pageSize).map(i => (
              <StyledTableRowComponent
                key={`invList-${i.id}`}
                hover={i.validationAge > 5 ? false : true}
                onClick={() => navigateToInvoiceDetails(i.id)}
                tabIndex={-1}
              >
                {props.canSignDebtRecognition &&
                  <StyledTableCellTextComponent padding="checkbox" onClick={event => handleClickCheckbox(event, i.id)}>
                    <Checkbox color="primary" checked={i.isSelected}
                      disabled={(props.invoices.find(i => i.isSelected) && props.invoices.find(i => i.isSelected)?.sellerCompany.id !== i.sellerCompany.id)}
                    />
                  </StyledTableCellTextComponent>
                }

                <StyledTableCellTextComponent padding="none">
                  {i.sellerCompany.name}
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  {i.reference}
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  {i.debtorReference}
                </StyledTableCellTextComponent>

                <StyledTableCellNumbersComponent padding="none">
                  <AmountFormatterComponent amount={i.debtAmount} />
                </StyledTableCellNumbersComponent>

                <StyledTableCellTextComponent padding="none">
                  <DateFormatterComponent date={i.issueDate} />
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  <DateFormatterComponent date={i.dueDate} />
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  <DateFormatterComponent date={i.confirmingDueDate} />
                </StyledTableCellTextComponent>

                <StyledTableCellNumbersComponent padding="none">
                  {i.daysToConfirmingDueDate > -1 && !i.paidDate ? <Box color="red.main"><NumberFormatterComponent value={i.daysToConfirmingDueDate} /></Box> : <NumberFormatterComponent value={i.daysToConfirmingDueDate} />}
                </StyledTableCellNumbersComponent>

                <StyledTableCellTextComponent padding="none">
                  <InvoiceNameComponent invoiceId={i.id} invoiceName={i.invoiceName} />
                </StyledTableCellTextComponent>

                <StyledTableCellTextComponent padding="none">
                  <Tooltip title={<IntlMessageComponent id="debtors.tooltip.refuseInvoice" />} style={{ cursor: 'default' }}>
                    <IconButton onClick={event => handleClickRefuse(event, i.id)}>
                      <Box
                        alignItems="center"
                        color='red.main'
                        display="flex"
                        fontSize="0.8em"
                        fontWeight="fontWeightBold"
                      >
                        <IconCancelOutlined color="red.main" />
                      </Box>
                    </IconButton>
                  </Tooltip>
                </StyledTableCellTextComponent>
              </StyledTableRowComponent>
            ))}

            {props.invoices.length > 0 &&
              <StyledTableRowComponent selected>
                <StyledTableCellTextComponent padding="checkbox" />
                <StyledTableCellTextComponent padding="none">
                  <Typography variant="body1">
                    <b>
                      <IntlMessageComponent id="debtor.approve.table.totalSellers" />
                      &nbsp;
                      <NumberFormatterComponent value={props.totalSellers} />
                    </b>
                  </Typography>
                </StyledTableCellTextComponent>
                <StyledTableCellTextComponent padding="none" />
                <StyledTableCellTextComponent padding="none" />

                <StyledTableCellTextComponent padding="none">
                  <Typography variant="body1">
                    <b>
                      <IntlMessageComponent id="debtor.approve.table.totalDebtAmount" />
                      &nbsp;
                      <AmountFormatterComponent amount={props.totalDebtAmount} />
                    </b>
                  </Typography>
                </StyledTableCellTextComponent>
                <StyledTableCellTextComponent padding="none" />
                <StyledTableCellTextComponent padding="none" />
                <StyledTableCellTextComponent padding="none" />
                <StyledTableCellTextComponent padding="none" />
                <StyledTableCellTextComponent padding="none" />
                <StyledTableCellTextComponent padding="none" />
              </StyledTableRowComponent>
            }

          </TableBody>
        </Table>
      </Box>

      <Box display="flex" height={60}>
        {props.canSignDebtRecognition &&
          <Box flexGrow={1} mt={2}>
            <ButtonComponent
              color="primary"
              variant="contained"
              disabled={props.invoices.length <= 0 || props.invoices.find(i => i.isSelected) === undefined}
              onClick={props.approveInvoices}
            ><IntlMessageComponent id="general.btn.approve" /></ButtonComponent>
          </Box>
        }
        <Box justifyContent="flex-end">
          <TablePagination
            component="div"
            count={props.invoices.length}
            rowsPerPage={props.pageSize}
            page={props.page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePageSizeChange}
            labelRowsPerPage={<IntlMessageComponent id="general.invoicePerPage" />}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
}
