/* @flow */

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';

import AmountFormatterComponent from 'components/formatters/amount';
import ButtonComponent from 'components/buttons/button';
import DateFormatterComponent from 'components/formatters/date';
import IconExclamationMarkOutlined from 'components/icons/outlined/exclamationMark';
import IntlMesssageComponent from 'components/formatters/intlMessage';
import NumberFormatterComponent from 'components/formatters/number';

import {
  PROTECTED_APP_TOS,
  PROTECTED_COMPANY,
  PROTECTED_COMPANY_DOCUMENTS,
  PROTECTED_COMPANY_SUBSCRIPTIONS,
  PROTECTED_COMPANY_UBOS,
  PROTECTED_COMPANY_USERS_PROCURATION,
  PROTECTED_ISO_LEADS,
  PROTECTED_PROFILE,
  PROTECTED_COMPANY_FIXCONTRACT,
  PROTECTED_COMPANY_BANKACCOUNTS,
  PROTECTED_CONFIRMING_PORTFOLIO
} from 'constants/pageRoutes';

import { ROLE_BUYER, ROLE_CONFIRMING, ROLE_DEBTOR, ROLE_ISO, ROLE_SELLER } from 'constants/companyRoles';
import { BUYER_ROLE_ADMINISTRATOR, CONFIRMING_ROLE_ADMINISTRATOR, SELLER_ROLE_ADMINISTRATOR } from 'constants/constants';
import DashboardService from 'services/DashboardService';
import InformAdminRequest from 'models/requests/dashboard/informAdminRequest';
import NotificationHelper from 'lib/notifications/index';

type NotificationViewComponentProps = {
  activeCompanyRole: number,
  activeBuyerRole: number,
  activeSellerRole: number,
  activeConfirmingRole: number,
  activeCompanyHasBuyerAdmins: boolean,
  activeCompanyHasSellerAdmins: boolean,
  activeCompanyHasConfirmingAdmins: boolean,
  notification: Notification,
  isProcessing: boolean;
  contactAdmin: () => void;
}

export default function NotificationViewComponent(props: NotificationViewComponentProps) {
  const history = useHistory();
  const activeCompany = useSelector(state => state.auth.user.activeCompany);

  const onClick = () => {
    switch (props.notification.id) {

      // Redirect to the company info page
      case 'ComplianceCheck':
        history.push(PROTECTED_COMPANY);
        break;
      case 'BankAccountToActivate':
        history.push(PROTECTED_COMPANY_BANKACCOUNTS);
        break;

      // Redirect to the membership page
      case 'MembershipExpired':
      case 'MembershipNewToPay':
      case 'MembershipToExpire':
      case 'CompanyInactiveExpiredMembership':
        history.push(PROTECTED_COMPANY_SUBSCRIPTIONS);
        break;

      // Redirect to the general conditions page
      case 'GeneralConditionsToSign':
        history.push(PROTECTED_APP_TOS);
        break;
      case 'Procuration':
        history.push(PROTECTED_COMPANY_USERS_PROCURATION.replace("{id}", activeCompany.companyUserId));
        break;

      // Redirect to UBO page
      case 'UBONew':
      case 'UBODraft':
      case 'UBORejected':
      case 'UBORenewNow':
      case 'UBORenewSoon':
      case 'UBORenewNowWithCountdown':
        history.push(PROTECTED_COMPANY_UBOS);
        break;

      // Redirect to documents page
      case 'CommissioningInvoicesToBeCreated':
      case 'CommissioningInvoicesToBeCreatedMSF':
      case 'ContractToBeSigned':
      case 'UrsaffAboutToExpire':
      case 'UrsaffAboutToExpireBE':
      case 'FiscalCertificateAboutToExpire':
      case 'QualiopiAboutToExpire':
      case 'AdditionalDocumentsRequired':
      case 'BankAccountMissingDocument':
        history.push(PROTECTED_COMPANY_DOCUMENTS);
        break;

      case 'LeadsOlderThan90Days':
        history.push({
          pathname: PROTECTED_ISO_LEADS,
          state: {
            filterByLeadsToClean: true
          }
        });
        break;

      case 'companyFixContractsDebtorRenewal':
      case 'companyFixContractsRenewal':
        history.push(PROTECTED_COMPANY_FIXCONTRACT);
        break;
      case 'PersonalDocumentsExpired':
        history.push({
          pathname: PROTECTED_PROFILE,
          state: {
            filterByLeadsToClean: true
          }
        });
        break;
      case 'InvoicesToSubmit':
        history.push(PROTECTED_CONFIRMING_PORTFOLIO);
        break;
      default:
        console.error('Missing action %s', props.notification);
    }
  };

  let userRoleAsString;
  switch (props.activeCompanyRole) {
    case ROLE_BUYER:
      userRoleAsString = 'buyer'; break;
    case ROLE_DEBTOR:
      userRoleAsString = 'debtor'; break;
    case ROLE_ISO:
      userRoleAsString = 'iso'; break;
    case ROLE_SELLER:
      userRoleAsString = 'seller'; break;
    case ROLE_CONFIRMING:
      userRoleAsString = 'confirming'; break;
    default:
      userRoleAsString = 'unknown'; break;
  }

  let notificationMessageId;
  switch (props.notification.id) {
    case 'CompanySuspended':
      notificationMessageId = `component.notification.${props.notification.id}.${userRoleAsString}`;
      break;
    default:
      notificationMessageId = `component.notification.${props.notification.id}`;
      break;
  }

  let notificationMessageValues = {};
  if (props.notification.amount) {
    notificationMessageValues.amount = (<AmountFormatterComponent amount={props.notification.amount} />);
  }
  if (props.notification.msfAmount) {
    notificationMessageValues.msfAmount = (<AmountFormatterComponent amount={props.notification.msfAmount} />);
  }
  if (props.notification.count) {
    notificationMessageValues.count = (<NumberFormatterComponent value={props.notification.count} />);
  }
  if (props.notification.dateFrom) {
    notificationMessageValues.dateFrom = (<DateFormatterComponent date={props.notification.dateFrom} />);
  }
  if (props.notification.dateTo) {
    notificationMessageValues.dateTo = (<DateFormatterComponent date={props.notification.dateTo} />);
  }
  if (props.notification.invoiceIds) {
    notificationMessageValues.invoiceIds = props.notification.invoiceIds.join(', ');
  }
  if (props.notification.generalSubstitution) {
    notificationMessageValues.generalSubstitution = props.notification.generalSubstitution;
  }

  let hasActionButton;
  let hasContactButton;
  switch (props.notification.id) {
    case 'CompanySubscribing':
    case 'CompanySuspended':
    case 'FUPBuyerUpdate':
    case 'UBOSubmitted':
    case 'CompanySubscribingPaid':
    case 'ComplianceBeingReviewed':
    case 'ComplianceExpired':
    case 'ProcurationUploaded':
    case 'GeneralConditionsWaiting':
      hasActionButton = false;
      break;
    case 'ComplianceCheck':
    case 'MembershipNewToPay':
    case 'MembershipToExpire':
    case 'MembershipExpired':
    case 'UBONew':
    case 'UBODraft':
    case 'UBORenewNowWithCountdown':
    case 'UBORenewNow':
    case 'UBORenewSoon':
    case 'UBORejected':
    case 'CompanyInactiveExpiredMembership':
    case 'BankAccountMissingDocument':
    case 'BankAccountToActivate':
      hasActionButton = true;
      if (props.activeCompanyRole === ROLE_BUYER && props.activeBuyerRole !== BUYER_ROLE_ADMINISTRATOR) {
        hasActionButton = false;
        if (props.activeCompanyHasBuyerAdmins)
          hasContactButton = true;
      }
      if (props.activeCompanyRole === ROLE_SELLER && props.activeSellerRole !== SELLER_ROLE_ADMINISTRATOR) {
        hasActionButton = false;
        if (props.activeCompanyHasSellerAdmins)
          hasContactButton = true;
      }
      if (props.activeCompanyRole === ROLE_CONFIRMING && props.activeConfirmingRole !== CONFIRMING_ROLE_ADMINISTRATOR) {
        hasActionButton = false;
        if (props.activeCompanyHasConfirmingAdmins)
          hasContactButton = true;
      }
      break;
    default:
      hasActionButton = true;
      break;
  }

  let action = null;
  if (hasActionButton) {
    action = (
      <ButtonComponent
        color="tertiary"
        variant="contained"
        onClick={onClick}
      >
        <Box minWidth={120}><IntlMesssageComponent id={`component.notification.${props.notification.id}.button`} /></Box>
      </ButtonComponent>
    );
  }
  if (hasContactButton) {
    action = (
      <ButtonComponent
        color="tertiary"
        variant="contained"
        onClick={props.contactAdmin}
        isProcessing={props.isProcessing}
      >
        <Box minWidth={120}><IntlMesssageComponent id={`component.notification.informadmin.button`} /></Box>
      </ButtonComponent>
    );
  }

  return (
    <Alert action={action} icon={<IconExclamationMarkOutlined color="error" />} severity="info" style={{ backgroundColor: '#e7f9ff' }}>
      <IntlMesssageComponent id={notificationMessageId} values={notificationMessageValues} />
    </Alert>
  );
}
