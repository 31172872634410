/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import * as reactGA from 'lib/analytics';

import Box from '@material-ui/core/Box';
import Error from '@material-ui/icons/Error';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import LoadingComponent from 'components/loading';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import { userSignInAfterRegistrationAction } from 'store/actions/authActions';

import { PROTECTED_APP_SUBSCRIPTION } from 'constants/pageRoutes';

type SignUpTab6ComponentProps = {
  email: string;
  password: string;
  loginSuccess: boolean;
  loginFailed: boolean;
  isISO: boolean;
}

class SignUpTab6Component extends React.Component<SignUpTab6ComponentProps, {}> {

  componentDidMount() {
    reactGA.initializeGA();
    reactGA.logEvent('SignUp', 'Sign Up Completed (Soft Registration)');
    TagManager.dataLayer({ dataLayer: { event: 'subscribed', formType: 'Subscription' } });
    this.props.userSignInAfterRegistrationAction(this.props.email, this.props.password);
  }

  componentWillUnmount = async () => {
    reactGA.logEvent('SignUp', 'Left Sign Up / Start Subscription');
  }

  render() {
    if (!this.props.loginSuccess && !this.props.loginFailed) {
      return (<LoadingComponent />);
    }

    if (this.props.loginFailed) {
      return (
        <Box textAlign="center">
          <Error color="error" fontSize="large" />
          <Box mt={1}>
            <Typography variant="body1">
              <IntlMessageComponent id="general.error" />
            </Typography>
          </Box>
        </Box>
      );
    }
    
    if (this.props.loginSuccess && this.props.isISO) {
      return (<Redirect to={`${PROTECTED_APP_SUBSCRIPTION}`} />);
    }

    let role = "";
    if (this.props.isDebtor) {
      role = "debtor.";
    }

    return (
      <React.Fragment>
        <PageSubTitleViewComponent id={`page.signup.tab6.${role}title`} />

        <Typography variant="body1">
          <IntlMessageComponent id={`page.signup.tab6.${role}text`} />
        </Typography>
        
        <Box mt={3}>
          <h3><IntlMessageComponent id={`page.signup.tab6.information.${role}title`} /></h3>

          <Typography variant="body1">
            <IntlMessageComponent id={`page.signup.tab6.information.${role}intro`} />
          </Typography>

          <Box mt={3} mb={3}>
            <ul>
              <li><IntlMessageComponent id={`page.signup.tab6.documents.${role}company`} /></li>
              <li><IntlMessageComponent id={`page.signup.tab6.documents.${role}personal`} /></li>
              <li><IntlMessageComponent id={`page.signup.tab6.documents.${role}id`} /></li>
              <li><IntlMessageComponent id={`page.signup.tab6.documents.${role}bank`} /></li>
            </ul>
          </Box>

          <Typography variant="body1">
            <IntlMessageComponent id={`page.signup.tab6.information.${role}outro`} />
          </Typography>
        </Box>

        <Box mt={3}>
          <ButtonComponent
            color="primary"
            variant="contained"
            component={Link}
            to={PROTECTED_APP_SUBSCRIPTION}
          ><IntlMessageComponent id="page.signup.tab6.button.finalize" /></ButtonComponent>
        </Box>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  loginSuccess: state.auth.user != null,
  loginFailed: state.auth.showLoginFailedMessage || state.auth.showServerErrorMessage,
  isDebtor: state.auth.user != null && state.auth.user.activeCompany != null && state.auth.user.activeCompany.isDebtor,
  isISO: state.auth.user != null && state.auth.user.activeCompany != null && state.auth.user.activeCompany.isISO
});

export default connect(mapStateToProps, {userSignInAfterRegistrationAction})(SignUpTab6Component);
