/* @flow */

import moment from 'moment';

export default class UpdateCompanyDetailsRequest {
  companyId: number;
  name: string;
  vatNumber: string;
  legalFormId: number;
  isin: string;
  stockExchange: string;
  activities: string;
  mainActivityCountryId: number;
  sourceOfFundsId: number;
  sourceOfFunds: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  zipCode: string;
  countryId: number;
  phone: string;
  ptCompanyCardOnlineCode: string;
  ptCommercialRegistrationOnlineCode: string;
  ptCommercialRegistrationOnlineCodeExpiry: moment;
}
