/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import IntlMessageComponent from 'components/formatters/intlMessage';
import LoadingComponent from 'components/loading';
import WizardComponent from 'components/wizard';

import SubscriptionTab1Component from './tab1/index';
import SubscriptionTab2Component from './tab2/index';
import SubscriptionTab3Component from './tab3/index';
import SubscriptionTab4Component from './tab4/index';
import SubscriptionTab5Component from './tab5/index';
import SubscriptionTab6Component from './tab6/index';

import CompanySubscriptionInfo from 'models/register/companySubscriptionInfo';

import RegisterService from 'services/registerService';

import NotificationHelper from 'lib/notifications';

type SubscriptionComponentProps = {
  activeCompanyId: number;
  activeCompanyRole: number;
}

type SubscriptionComponentState = {
  activeStepId: number;
  companySubscriptionInfo: CompanySubscriptionInfo;
  isLoading: boolean;
  steps: {id: number, title: IntlMessageComponent}[];
}

class SubscriptionComponent extends React.Component<SubscriptionComponentProps, SubscriptionComponentState> {
  static STEP_COMPANY_INFO = 1;
  static STEP_PERSON_INFO = 2;
  static STEP_DOCUMENTS = 3;
  static STEP_INVESTMENTPROFILE = 4;
  static STEP_LEGAL = 5;
  static STEP_FINALE = 6;

  constructor(props) {
    super(props);

    this.state = {
      activeStepId: 0,
      companySubscriptionInfo: null,
      isLoading: true,
      steps: null
    };
  }

  componentDidMount = async () => {
    try {
      const response = await RegisterService.getCompanySubscriptionInfo(this.props.activeCompanyId, this.props.activeCompanyRole);
      const activeStepId = this.getNextStepId(response.lastStep + 1, response);
      console.log('activeStepId: ', activeStepId);

      this.setState({
        activeStepId,
        companySubscriptionInfo: response,
        isLoading: false,
        steps: this.getSteps(response)
      });
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  getSteps = (response: CompanySubscriptionInfo) => {
    const tabsNumbers = [];
    if (response.showCompanyTab) tabsNumbers.push(SubscriptionComponent.STEP_COMPANY_INFO);
    if (response.showPersonTab) tabsNumbers.push(SubscriptionComponent.STEP_PERSON_INFO);
    if (response.showDocumentTab) tabsNumbers.push(SubscriptionComponent.STEP_DOCUMENTS);
    if (response.showInvestmentProfileTab) tabsNumbers.push(SubscriptionComponent.STEP_INVESTMENTPROFILE);
    if (response.showLegalTab) tabsNumbers.push(SubscriptionComponent.STEP_LEGAL);
    tabsNumbers.push(SubscriptionComponent.STEP_FINALE);
    return tabsNumbers.map(num => ({id: num, title: <IntlMessageComponent id={`subscription.tab${num}.header`} />}));
  }

  getNextStepId = (activeStepId: number, response: CompanySubscriptionInfo) => {
    if (activeStepId === SubscriptionComponent.STEP_COMPANY_INFO && !response.showCompanyTab) activeStepId++;
    if (activeStepId === SubscriptionComponent.STEP_PERSON_INFO && !response.showPersonTab) activeStepId++;
    if (activeStepId === SubscriptionComponent.STEP_DOCUMENTS && !response.showDocumentTab) activeStepId++;
    if (activeStepId === SubscriptionComponent.STEP_INVESTMENTPROFILE && !response.showInvestmentProfileTab) activeStepId++;
    if (activeStepId === SubscriptionComponent.STEP_LEGAL && (!response.showLegalTab || response.skipGcStep)) activeStepId++;
    if (activeStepId >= SubscriptionComponent.STEP_FINALE) return SubscriptionComponent.STEP_FINALE;
    return activeStepId;
  }

  getStepContent = () => {
    switch (this.state.activeStepId) {
      case SubscriptionComponent.STEP_COMPANY_INFO:
        return (<SubscriptionTab1Component successHandler={this.successHandler} company={this.state.companySubscriptionInfo.company} />);
      case SubscriptionComponent.STEP_PERSON_INFO:
        return (<SubscriptionTab2Component successHandler={this.successHandler} />);
      case SubscriptionComponent.STEP_DOCUMENTS:
        return (<SubscriptionTab3Component successHandler={this.successHandler} />);
      case SubscriptionComponent.STEP_INVESTMENTPROFILE:
        return (<SubscriptionTab4Component successHandler={this.successHandler} />);
      case SubscriptionComponent.STEP_LEGAL:
        return (<SubscriptionTab5Component successHandler={this.successHandler} companySubscriptionInfo={this.state.companySubscriptionInfo} />);
      case SubscriptionComponent.STEP_FINALE:
        return (<SubscriptionTab6Component companySubscriptionInfo={this.state.companySubscriptionInfo} />);
      default:
        return 'Unknown step';
    }
  };

  successHandler = () => {
    NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
    const nextStepId = this.getNextStepId(this.state.activeStepId + 1, this.state.companySubscriptionInfo);
    this.setState({ activeStepId: nextStepId });
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <WizardComponent
        activeStepId={this.state.activeStepId}
        steps={this.state.steps}
      >
        {this.getStepContent()}
      </WizardComponent>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyRole: state.auth.user.activeCompany.role
});

export default connect(mapStateToProps)(SubscriptionComponent);
