import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import IconClose from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';

const useStyles = makeStyles(theme => ({
  closeButton: {
    color: '#000',
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1)
  },
  icon: {
    color: '#000'
  },
  itemIcon: {
    minWidth: '40px'
  },
  paper: {
    backgroundColor: '#fff',
    padding: theme.spacing(2),
  }
}));

export default function CompanyInfoSentModalComponent(props) {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open
      onClose={props.handleClose}
      PaperProps={{ classes: { root: classes.paper } }}
      scroll="body"
    >
      <DialogTitle>
        <Typography variant="h6">
          <IntlMessageComponent id='company.details.modal.title' />
        </Typography>
        <IconButton className={classes.closeButton} onClick={props.handleClose}>
          <IconClose />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box>
          <IntlMessageComponent id='page.company.saved' />
        </Box>
        <Box pt={4} pb={2}>
          <ButtonComponent
            color="primary"
            variant="contained"
            onClick={props.handleClose}
          ><IntlMessageComponent id='company.details.modal.understood' /></ButtonComponent>
        </Box>
      </DialogContent>
    </Dialog>
  );
}