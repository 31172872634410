/* @flow */

import moment from 'moment';
import CompanyDetails from 'models/companies/companyDetails';
import UpdateCompanyDetailsRequest from 'models/requests/companies/updateCompanyDetailsRequest';
import {isCountryFranceOrDomTom} from 'lib/countryHelpers';

export default class CompanyDetailsFormModel {
  name: string;
  vatNumber: string;
  legalFormId: number;
  isin: string;
  stockExchange: string;
  activities: string;
  mainActivityCountryId: number;
  sourceOfFundsId: number;
  sourceOfFunds: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  zipCode: string;
  countryId: number;
  phone: string;
  isISO: boolean;
  isSeller: boolean;
  isBuyer: boolean;
  isDebtor: boolean;
  isConfirming: boolean;
  ptCompanyCardOnlineCode: string;
  ptCommercialRegistrationOnlineCode: string;
  ptCommercialRegistrationOnlineCodeExpiry: moment;
  orias: boolean;

  constructor(details: CompanyDetails) {
    this.name = details.name || '';
    this.vatNumber = details.vatNumber || '';
    this.legalFormId = details.legalFormId || 0;
    this.isin = details.isin || '';
    this.stockExchange = details.stockExchange || '';
    this.activities = details.activities || '';
    this.mainActivityCountryId = details.mainActivityCountryId || 0;
    this.sourceOfFundsId = details.sourceOfFundsId || 0;
    this.sourceOfFunds = details.sourceOfFunds || '';
    this.addressLine1 = details.addressLine1 || '';
    this.addressLine2 = details.addressLine2 || '';
    this.city = details.city || '';
    this.zipCode = details.zipCode || '';
    this.countryId = details.countryId || 0;
    this.phone = details.phone || '';
    this.isISO = details.isISO;
    this.isSeller = details.isSeller;
    this.isBuyer = details.isBuyer;
    this.isDebtor = details.isDebtor;
    this.isConfirming = details.isConfirming;
    this.ptCompanyCardOnlineCode = details.ptCompanyCardOnlineCode || '';
    this.ptCommercialRegistrationOnlineCode = details.ptCommercialRegistrationOnlineCode || '';
    this.ptCommercialRegistrationOnlineCodeExpiry = details.ptCommercialRegistrationOnlineCodeExpiry || moment().startOf('d');
    this.orias = details.orias;
  }

  toRequest(companyId: number) {
    const request = new UpdateCompanyDetailsRequest();
    request.companyId = companyId;
    request.name = this.name;
    request.vatNumber = this.vatNumber;
    request.legalFormId = this.legalFormId;
    request.isin = this.isin;
    request.stockExchange = this.stockExchange;
    request.activities = this.activities;
    request.mainActivityCountryId = this.mainActivityCountryId;
    request.sourceOfFundsId = this.sourceOfFundsId
    request.sourceOfFunds = this.sourceOfFunds;
    request.addressLine1 = this.addressLine1;
    request.addressLine2 = this.addressLine2;
    request.city = this.city;
    request.zipCode = this.zipCode;
    request.countryId = this.countryId;
    request.phone = this.phone;
    request.ptCompanyCardOnlineCode = this.ptCompanyCardOnlineCode;
    request.ptCommercialRegistrationOnlineCode = this.ptCommercialRegistrationOnlineCode;
    request.ptCommercialRegistrationOnlineCodeExpiry = moment(this.ptCommercialRegistrationOnlineCodeExpiry).format('YYYY/MM/DD');
    if (this.isISO && isCountryFranceOrDomTom(this.countryId))
    {
      request.orias = this.orias;
    }
    return request;
  }
}
