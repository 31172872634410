/* @flow */


import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import PhoneNumberComponent from 'components/formFields/phoneNumber';
import ButtonComponent from 'components/buttons/button';
import CountryFieldSellersComponent from 'components/formFields/countries/sellers';
import FormErrorsComponent from 'components/formErrors';
import SourceOfFundsFieldComponent from 'components/formFields/types/sourceOfFunds';
import InputAmountComponent from 'components/formatters/inputAmount';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import SetCompanyInfoForm from './model';
import { SOURCEOFFUNDS_OTHER } from 'constants/constants';

type RegisterSellerCompanyTab2ViewComponentProps = {
  errors: Map<string,?string>;
  form: SetCompanyInfoForm;
  formErrors: string[];
  isProcessing: boolean;
  workingWithMandates: boolean;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
  handlePhoneChange: (value: string) => void;
  submitForm: () => void;
}

export default function RegisterSellerCompanyTab2ViewComponent(props: RegisterSellerCompanyTab2ViewComponentProps) {
  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <PageSubTitleViewComponent
        id="page.iso.company.register.tab1.companyInfo"
        data-walkme="registerseller2-title-companyInfo"
      />

      <Box mt={3}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <Box px={4}>
              <PhoneNumberComponent
                id="form.phone"
                data-walkme="subscription-field-ndetelephone"
                label={<IntlMessageComponent id="form.company.phone.label" />}
                error={!!props.errors.get('phone')}
                helperText={(props.errors.get('phone')) ?
                  <IntlMessageComponent id={props.errors.get('phone')} /> :
                  <IntlMessageComponent id="form.phone.helperText" />}
                value={props.phone}
                onChange={props.handlePhoneChange}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.isin"
                data-walkme="createlead-field-isin"
                label={<IntlMessageComponent id="form.isin.label" />}
                error={!!props.errors.get('isin')}
                helperText={(props.errors.get('isin')) ?
                  <IntlMessageComponent id={props.errors.get('isin')} /> : ''}
                value={props.isin}
                onChange={props.handleChange('isin')}
                fullWidth
                margin="normal"
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <TextField
                id="form.activities"
                data-walkme="subscription-field-activities"
                label={<IntlMessageComponent id="form.activities.label" />}
                error={!!props.errors.get('activities')}
                helperText={(props.errors.get('activities')) ?
                  <IntlMessageComponent id={props.errors.get('activities')} /> : ''}
                value={props.form.activities}
                onChange={props.handleChange('activities')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <CountryFieldSellersComponent
                id="form.mainActivityCountry"
                label={<IntlMessageComponent id="form.mainActivityCountry.label" />}
                error={!!props.errors.get('mainActivityCountryId')}
                helperText={props.errors.get('mainActivityCountryId') ?
                  <IntlMessageComponent id={props.errors.get('mainActivityCountryId')} /> : ''}
                value={props.form.mainActivityCountryId}
                changeHandler={props.handleTypeChange('mainActivityCountryId')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={4}>
              <SourceOfFundsFieldComponent
                id="form.sourceOfFunds"
                data-walkme="subscription-field-sourceOfFunds"
                label={<IntlMessageComponent id="form.sourceOfFunds.label" />}
                error={!!props.errors.get('sourceOfFundsId')}
                helperText={(props.errors.get('sourceOfFundsId')) ?
                  <IntlMessageComponent id={props.errors.get('sourceOfFundsId')} /> : ''}
                value={props.form.sourceOfFundsId}
                changeHandler={props.handleTypeChange('sourceOfFundsId')}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>

          {props.form.sourceOfFundsId === SOURCEOFFUNDS_OTHER &&
            <Grid item xs={12} md={6}>
              <Box px={4}>
                <TextField
                  id="form.sourceOfFundsSpecification"
                  data-walkme="subscription-field-sourceOfFunds"
                  label={<IntlMessageComponent id="form.sourceOfFundsSpecification.label" />}
                  error={!!props.errors.get('sourceOfFunds')}
                  helperText={(props.errors.get('sourceOfFunds')) ?
                    <IntlMessageComponent id={props.errors.get('sourceOfFunds')} /> : ''}
                  value={props.form.sourceOfFunds}
                  onChange={props.handleChange('sourceOfFunds')}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
            </Grid>
          }

          {props.workingWithMandates &&
            <Grid item xs={12} md={6}>
              <Box px={4}>
                <TextField
                  id="form.mandateAmount"
                  label={<IntlMessageComponent id="form.mandateAmount.label" />}
                  helperText={(props.errors.get('mandateAmount')) ?
                    <IntlMessageComponent id={props.errors.get('mandateAmount')} /> : ''}
                  error={!!props.errors.get('mandateAmount')}
                  value={props.form.mandateAmount}
                  onChange={props.handleChange('mandateAmount')}
                  InputProps={{
                    inputComponent: InputAmountComponent,
                    startAdornment: <InputAdornment position="start">€</InputAdornment>
                  }}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
            </Grid>
          }
        </Grid>
      </Box>

      <Box mt={10}>
        <PageSubTitleViewComponent
          id="page.iso.company.register.tab1.bankInfo"
          data-walkme="registerseller2-title-bankinfo"
        />

        <Box mt={3}>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={12} md={6}>
              <Box px={4}>
                <CountryFieldSellersComponent
                  id="form.bankCountry"
                  label={<IntlMessageComponent id="form.bankCountry.label" />}
                  error={!!props.errors.get('bankCountryId')}
                  helperText={(props.errors.get('bankCountryId')) ?
                    <IntlMessageComponent id={props.errors.get('bankCountryId')} /> : ''}
                  value={props.form.bankCountryId}
                  changeHandler={props.handleTypeChange('bankCountryId')}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box px={4}>
                <TextField
                  id="form.bic"
                  data-walkme="registerseller2-field-bic"
                  label={<IntlMessageComponent id="form.bankIdentifier.label" />}
                  error={!!props.errors.get('bankIdentifier')}
                  helperText={(props.errors.get('bankIdentifier')) ? <IntlMessageComponent id={props.errors.get('bankIdentifier')} /> : ''}
                  value={props.form.bankIdentifier}
                  onChange={props.handleChange('bankIdentifier')}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box px={4}>
                <TextField
                  id="form.iban"
                  data-walkme="registerseller2-field-iban"
                  label={<IntlMessageComponent id="form.bankAccount.label" />}
                  error={!!props.errors.get('bankAccount')}
                  helperText={(props.errors.get('bankAccount')) ?
                    <IntlMessageComponent id={props.errors.get('bankAccount')} /> : ''}
                  value={props.form.bankAccount}
                  onChange={props.handleChange('bankAccount')}
                  fullWidth
                  margin="normal"
                  required
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.continue" /></ButtonComponent>

        <MandatoryFormFieldsComponent />
      </Box>
    </React.Fragment>
  );
}
